<script setup>
import { computed } from 'vue';
import CabColorSection from '@/components/configuratorSections/CabColorSection';
import ConfigurationRadioSelection from '@/components/ConfigurationRadioSelection';
import '@/styles/configurator-section.css';

const emit = defineEmits(['select']);

const props = defineProps({
  cabColour: {
    type: Object,
    default: null,
  },
  exterior: {
    type: Array,
    default: () => {
      return [];
    },
  },
  images: {
    type: Object,
    default: null,
  },
});

import { useMainStore } from '@/store/index.js';

const store = useMainStore();

const exteriorStyling = computed(() => props.exterior?.find(x => x.id === 'exteriorStyling'));
const lighting = computed(() => props.exterior?.find(x => x.id === 'lighting'));


</script>
'<template>
  <section
    id="exterior"
    class="configurator-section"
  >
    <CabColorSection
      :items="props.cabColour"
      :front-image="props.images?.view"
      :color-header-title="'exteriorHeaderTitle'"
      :color-sub-header-title="'cabColourHeaderTitle'"
      @select="emit('select', $event, 'cabColour')"
    />
    <ConfigurationRadioSelection
      v-if="exteriorStyling"
      v-model="store.selected.exterior[exteriorStyling.id]"
      :type="exteriorStyling.id"
      :options="exteriorStyling.options"
      :title="exteriorStyling.title"
      @select="emit('select', $event, 'exterior')"
    />
    <ConfigurationRadioSelection
      v-if="lighting"
      v-model="store.selected.exterior[lighting.id]"
      :type="lighting.id"
      :options="lighting.options"
      :title="lighting.title"
      @select="emit('select', $event, 'exterior')"
    />
  </section>
</template>

<script setup>
import { computed } from 'vue';
import NumberInput from '@/components/NumberInput.vue';

const props = defineProps(
  {
    modelValue: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    title: {
      type: String,
      default: '',
    },
    title2: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    header: {
      type: String,
      default: '',
    },
  },
);

const emit = defineEmits(['update:modelValue']);

const currentValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

</script>
<template>
  <div class="card">
    <div class="card-img">
      <img
        :src="imageUrl"
        alt="Card Image"
      >
    </div>
    <div class="card-body">
      <p class="sdds-headline-05">
        {{ $i18n(title) }}&nbsp;{{ $i18n(title2) }}
      </p>
      <NumberInput
        v-model="currentValue"
        :header="props.header"
        :min="props.min"
        :max="props.max"
      />
    </div>
  </div>
</template>

<style scoped>
.card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
}

.card-img {
  width: 20%;
  background-color: var(--sdds-grey-300);
  border-radius: 1rem;
  padding: 2rem;
}

.card-img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-body {
  display: flex;
  max-width: 80%;
  flex-direction: column;
  padding-left: var(--sdds-spacing-element-20);
}

.card-body > p {
  margin: 0;
  line-height: 5rem;
}

.card-body > .general-container {
  padding-bottom: 0;
}


@media only screen and (max-width: 380px) {
  .card-body {
    padding-left: var(--sdds-spacing-element-8);
    max-width: 60%;
  }

  .card-img {
    width: 50%;
    padding: 2rem;
  }
}

@media only screen and (min-width: 381px) and (max-width: 480px) {
  .card-body {
    padding-left: var(--sdds-spacing-element-8);
    max-width: 65%;
  }

  .card-img {
    width: 50%;
    padding: 4rem;
  }
}

@media only screen and (max-width: 1024px) {
  .active-offers__card-list>* {
    width: 100%;
    margin: unset;
  }
}


</style>

<script setup>
import { ref, onMounted } from 'vue';
import PageSpinner from '../../components/PageSpinner.vue';
import ActiveOffersAndQuotationsView from './ActiveOffersAndQuotationsView.vue';
import ActiveOffersView from './ActiveOffersView.vue';
import { useMainStore } from '@/store/index';

const isLoading = ref(true);
const showAvailableOffersAndQuotationsView = ref(false);

const store = useMainStore();

const setFeatureToggle = async () => {
  const response = store.featureToggleData[`${store.market}#webapp_show_availableOffersQuotationsPage_demo`];
  if (response) {
    const { isActive, isEffectiveUser, isFeatureDateValid } = response;
    showAvailableOffersAndQuotationsView.value = isActive && isEffectiveUser && isFeatureDateValid;
  }
};

onMounted(async () => {
  isLoading.value = true;
  await setFeatureToggle();
  isLoading.value = false;
});

</script>
<template>
  <template v-if="isLoading">
    <PageSpinner />
  </template>
  <template v-else>
    <template v-if="showAvailableOffersAndQuotationsView">
      <ActiveOffersAndQuotationsView :show-quotations="showAvailableOffersAndQuotationsView" />
    </template>
    <template v-else>
      <ActiveOffersView />
    </template>
  </template>
</template>

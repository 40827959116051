import { onMounted, ref, computed, inject } from 'vue';

const currentIntersectedId = ref(null);

const useItemIntersection = () => {
  let observer = null;

  const adobeEvent = inject('adobeEvent');

  onMounted(() => {
    const options = {
      root: document,
      threshold: 0.5,
    };
    const handler = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          currentIntersectedId.value = entry.target.id;
          adobeEvent({
            event: 'pageScroll',
            section: currentIntersectedId.value,
          });
        }
      });
    };
    observer = new IntersectionObserver(handler, options);
  });

  const intersectedId = computed(() => {
   return currentIntersectedId;
  });

  const observe = (idList) => {
    idList.map(id => {
      const item = document.querySelector(`#${id}`);
      if (item) {
        observer.observe(item);
      }
    });
  };

  return {
    observe,
    intersectedId,
  };
};

export { useItemIntersection };

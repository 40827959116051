<script setup>
import { computed } from 'vue';
import InformationIcon from './InformationIcon';
import { useConfiguratorApi } from '../composables/configurator';
import { useMainStore } from '../store/index.js';

const { totalPrice, buyBackValue } = useConfiguratorApi();
const store = useMainStore();

const props = defineProps({
  selectorId: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: 'top',
  },
});

// Sum of prices directly related to the actual truck, selections and the base cost
const productPrice = computed(() => {
  return totalPrice.value.priceBreakdown.basePrice +
    totalPrice.value.priceBreakdown.selectedProductOptionsPrice;
});

// Sum of prices for all services, including repair and maintenance selections and base cost
const servicePrice = computed(() => {
  return totalPrice.value.priceBreakdown.selectedServiceOptionsPrice +
    totalPrice.value.priceBreakdown.repairMaintenancePrice +
    totalPrice.value.priceBreakdown.selectedOptionsRepairMaintenancePrice;
});

</script>

<template>
  <sdds-tooltip
    :placement="props.placement"
    :selector="`#${selectorId}`"
    mouse-over-tooltip="true"
  >
    <ul>
      <li><strong>{{ $i18n('totalPrice') }}:</strong> {{ totalPrice.price }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffix) }}</span></li>
      <li>- {{ $i18n('truck') }}: {{ productPrice }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffix) }}</span></li>
      <li>- {{ $i18n('services') }}: {{ servicePrice }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffix) }}</span></li>
      <template v-if="store.showYearEndOfferBanner">
        <li><span style="font-size: x-small;">{{ $i18n('yearEndOfferPriceHelperText2') }}</span></li>
      </template>
    </ul>
    <br>
    <ul v-if="buyBackValue">
      <li>{{ $i18n('priceInfotipNetValue') }}</li>
      <li>{{ $i18n('priceInfotipBuyBackValue') }} {{ buyBackValue }}</li>
    </ul>
  </sdds-tooltip>
  <InformationIcon :id="selectorId" />
</template>

<style scoped>
  sdds-tooltip > ul {
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    white-space: pre-wrap;
  }
</style>

<script setup>
  // import { computed } from 'vue';

  const emit = defineEmits(['select']);

  const props = defineProps({
    option: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  });
</script>

<template>
  <div
    class="configurator-selection__color-option"
    :class="{ selected }"
    @click="emit('select', option)"
  >
    <h5>{{ $i18n(option.value) }}</h5>
    <div
      class="configurator-selection__color-option--circle"
      :style="`background-color: ${option.description}`"
    />
  </div>
</template>

<style scoped>
  h5 {
    margin: 0;
    text-align: center;
  }

  .configurator-selection__color-option {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: var(--sdds-spacing-element-8);
  }

  .configurator-selection__color-option:hover {
    cursor: pointer;
  }

  .configurator-selection__color-option.selected > .configurator-selection__color-option--circle {
    border-color: var(--sdds-blue);
  }

  .configurator-selection__color-option--circle {
    width: 4em;
    height: 4em;
    border-radius: 50%;
    border: 3px solid transparent;
    transition: border-color 0.2s ease-out, outline 0.1s ease-out;
  }

  .configurator-selection__color-option:hover:not(.selected) > .configurator-selection__color-option--circle {
    border-color: var(--sdds-grey-300);
  }
</style>

<script setup>
  import SelectOptionV2 from './SelectOptionV2';
  import '../styles/configurator-selection.css';

  const emit = defineEmits(['select', 'update:modelValue']);
  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      default: () => {
        return null;
      },
    },
    options: {
      type: Array,
      required: true,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    showInformation: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInsidePackage: {
      type: Boolean,
      default: false,
    },
    disabledSelections: {
      type: Array,
      default: () => [],
    },
    justifyContentCenter: {
      type: Boolean,
      default: false,
    },
  });

  const select = (option) => {
    emit('select', { option, type: props.type });
    emit('update:modelValue', option);
  };
</script>

<template>
  <div class="configurator-selection">
    <h3
      v-if="props.title"
      class="configurator-selection__title"
    >
      {{ $i18n(title) }}
    </h3>
    <div
      class="configurator-selection__options"
      :class="{ package: isInsidePackage, 'justify-content-center': justifyContentCenter }"
    >
      <SelectOptionV2
        v-for="option in props.options"
        :key="option.id"
        :option="option"
        :selected="modelValue?.id == option.id"
        :show-description="props.showDescription"
        :show-information="props.showInformation"
        :disabled="disabled || disabledSelections.includes(option.id)"
        @click="select(option)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../store/index.js';
import { useConfiguratorApi } from '../composables/configurator';
import PriceSuffix from './PriceSuffix';
import PriceTooltip from './PriceTooltip.vue';
import MonthlyPriceTooltip from './MonthlyPriceTooltip';

const { totalFormattedPrice, totalPrice, repairMaintenanceBasePrice } = useConfiguratorApi();
const router = useRouter();
const store = useMainStore();

const emit = defineEmits(['scrollToSection']);

defineProps({
  title: {
    type: String,
    default: '',
  },
});

const isHamburgerMenuOpen = ref(false);
const sections = computed(() => store.sections);
const paymentTypes = store.options.payment ? store.options.payment.find(o => o.id === 'paymentType').options : [];

const yearEndOfferPrice = computed(() => {
  if (store.showYearEndOfferBanner) {
    const calculatedPrice = (store.selectedPaymentType === 'leasing' ? totalPrice.value.price : totalPrice.value.monthlyPrice) - repairMaintenanceBasePrice.value + 1;
    return calculatedPrice.toLocaleString('en').replace(/,/g," ");
  } else return totalFormattedPrice.value.price;
});

const goToSummary = () => {
  router.push({ name: 'SummaryView', params: { market: store.market }, query: { id: store.offerId } });
};

const toggleHamburgerMenu = () => {
  isHamburgerMenuOpen.value = !isHamburgerMenuOpen.value;
};

const changePaymentType = (paymentType) => {
  store.selectedPaymentType = paymentType;
  store.selected.payment.paymentType = paymentTypes.find(p => p.value === paymentType);
};

</script>

<template>
  <nav
    class="sdds-nav sdds-nav--secondary sdds-nav--sticky sdds-nav--white configuration-header"
    :class="{ 'sdds-nav__avatar--opened': isHamburgerMenuOpen, 'height-86': paymentTypes.length > 1 }"
  >
    <div class="sdds-nav__left sdds-nav__configurator-navigation">
      <ul
        class="sdds-nav__toolbar-menu"
      >
        <li
          class="sdds-nav__item sdds-nav__avatar sdds-nav__hamburger"
        >
          <button
            class="sdds-nav__avatar-btn"
            data-dd-action-name="toggle hamburger menu"
            @click="toggleHamburgerMenu()"
          >
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.97 6.998a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 15.982a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 24.966a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1Z"
                fill="currentColor"
              />
            </svg>
          </button>

          <ul class="sdds-nav__avatar-menu">
            <li
              v-if="store.selectedPaymentType !== 'cash'"
              class="sdds-nav__avatar-item"
            >
              <span
                class="sdds-nav__avatar-item-core"
                @click="emit('scrollToSection', 'leasing-options'), toggleHamburgerMenu()"
              >
                {{ $i18n('leasingOptionsText') }}
              </span>
            </li>
            <li
              v-for="section in sections"
              :key="section.id"
              class="sdds-nav__avatar-item"
              @click="emit('scrollToSection', section.id), toggleHamburgerMenu()"
            >
              <span
                class="sdds-nav__avatar-item-core"
              >
                {{ $i18n(section.name) }}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="sdds-nav__right sdds-nav__price">
      <div
        class="payment-container"
        :class="{ 'height-86': paymentTypes.length > 1 }"
      >
        <template v-if="paymentTypes.length > 1">
          <div class="paymentType-div">
            <div
              v-for="paymentType in paymentTypes"
              :key="paymentType.id"
              class="paymentType-option-div"
            >
              <a
                :data-analytics="`{'eventComponent':'sidebar','eventType':'button','eventLabel':'change payment type - ${paymentType.value}'}`"
                class="sdds-navigation-tabs--tab"
                :class="{
                  'sdds-navigation-tabs--tab__active': paymentType.value === store.selectedPaymentType,
                  'sdds-navigation-tabs--tab__inactive': paymentType.value !== store.selectedPaymentType,
                }"
                :data-dd-action-name="`change payment type - ${paymentType.value}`"
                @click="changePaymentType(paymentType.value)"
              >{{ $i18n(paymentType.description || paymentType.value) }}</a>
            </div>
          </div>
          <div class="sdds-divider-light sdds-u-mt0" />
        </template>
        <div
          class="payment-info"
          :class="{ 'sdds-u-textalign-center payment-info-leasing-padding': paymentTypes.length > 1 && store.selectedPaymentType === 'leasing' }"
        >
          <template v-if="store.selectedPaymentType === 'leasing'">
            <span class="sdds-u-textalign-start">
              <template v-if="store.showYearEndOfferBanner">
                <span class="year-end-offer-price">{{ yearEndOfferPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
                <span class="el-margin-left-8">
                  <PriceTooltip
                    placement="bottom"
                    selector-id="price-tooltip-header"
                  />
                </span>
                <!-- <br><span style="font-size: large;">{{ totalFormattedPrice.price }}</span>&nbsp;<span style="font-size: small;">{{ $i18n('yearEndOfferPriceHelperText1') }}</span>
                <br><span style="font-size: x-small;">{{ $i18n('yearEndOfferPriceHelperText1') }}</span> -->
              </template>
              <template v-else>
                <span>{{ totalFormattedPrice.price }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
                <span class="el-margin-left-8">
                  <PriceTooltip
                    placement="bottom"
                    selector-id="price-tooltip-header"
                  />
                </span>
              </template>
            </span>
          </template>
          <template v-if="store.selectedPaymentType === 'cash'">
            <div class="sdds-u-inline-block">
              <span class="sdds-u-block sdds-detail-02">{{ $i18n('purchasePrice') }}</span>
              <div>
                <span class="payment-amount">{{ totalFormattedPrice.price }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
              </div>
            </div>
            <span class="sdds-divider-dark-vertical" />
            <div class="sdds-u-inline-block">
              <span class="sdds-u-block sdds-detail-02">{{ $i18n('monthlyPrice') }}</span>
              <div>
                <template v-if="store.showYearEndOfferBanner">
                  <span class="payment-amount year-end-offer-price">{{ yearEndOfferPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixMonthly" /></span>
                  <span class="el-margin-left-8"><MonthlyPriceTooltip selector-id="monthly-price-tooltip-header" /></span>
                  <!-- <br><span style="font-size: large;">{{ totalFormattedPrice.monthlyPrice }}</span>&nbsp;<span style="font-size: small;">{{ $i18n('yearEndOfferPriceHelperText1') }}</span>
                  <br><span style="font-size: x-small;">{{ $i18n('yearEndOfferPriceHelperText1') }}</span> -->
                </template>
                <template v-else>
                  <span class="payment-amount">{{ totalFormattedPrice.monthlyPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixMonthly" /></span>
                  <span class="el-margin-left-8"><MonthlyPriceTooltip selector-id="monthly-price-tooltip-header" /></span>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="sdds-nav__right sdds-nav__proceed"
      :class="{
        'summary-container-responsive': (paymentTypes.length > 1) || (paymentTypes.length < 2 && store.selectedPaymentType === 'cash'),
        'summary-container-responsive-top': (paymentTypes.length < 2 && store.selectedPaymentType === 'cash'),
      }"
    >
      <button
        class="sdds-btn sdds-btn-primary"
        data-analytics="{'eventComponent':'header','eventType':'button','eventLabel':'go to summary'}"
        data-dd-action-name="go to summary"
        @click="goToSummary"
      >
        {{ $i18n('viewSummary') }}
      </button>
    </div>
  </nav>
</template>

<style scoped>
  .sdds-nav > button {
    padding: var(--sdds-spacing-element-20);
  }
  .sdds-nav__avatar-menu {
    right: auto;
    overflow: auto;
    max-height: calc(100vh - 68px);
  }
  .sdds-nav--white {
    background-color: var(--sdds-white);
    border-bottom: 1px solid;
    border-color: #e2e2e2;
  }
    .sdds-nav--white .sdds-nav__app-name { color: var(--sdds-blue); }
    .sdds-nav--white .sdds-nav__app-logo { background-color: var(--sdds-white); border-color: #e2e2e2; }

  .sdds-nav--sticky {
    position: sticky;
    top: 0;
  }
  .sdds-nav__hamburger {
    border: 0;
  }
  .sdds-nav__hamburger > button {
    background-color: var(--sdds-white);
    color: var(--sdds-blue);
    padding: 0.5em;
  }
  .sdds-nav__hamburger > button:focus {
    background-color: var(--sdds-white);
    color: var(--sdds-blue);
  }

  .sdds-nav .sdds-nav__avatar-btn:hover, .sdds-nav .sdds-nav__avatar-btn:active:hover {
    background-color: var(--sdds-white);
    color: var(--sdds-blue);
  }

.sdds-nav--secondary {
    height: 72px;
    place-items: center;
    justify-content: flex-end;
  }
  .sdds-nav__price {
    padding: 0 var(--sdds-spacing-element-20);
    flex-flow: column;
  }
  .sdds-nav__price .payment-info {
    font-size: 2em;
    white-space: nowrap;
  }
  .sdds-nav__price .payment-info .payment-amount {
    font-size: 1em;
  }
  .priceSuffix__container {
    font-size: 0.4em;
    line-height: 1em;
  }

  .sdds-nav__proceed {
    width: 20em;
    justify-content: flex-end;
  }
    .sdds-nav__proceed > button {
      padding: 0 1em;
      margin-right: 0.5em;
    }

  .sdds-nav__configurator-navigation {
    width: 100%;
  }
    .sdds-nav__configurator-navigation ol {
      display: flex;
      list-style-type: none;
      margin-left: 4em;
    }
    .sdds-nav__configurator-navigation li {
      line-height: var(--sdds-spacing-element-24);
    }

    .sdds-nav__configurator-navigation a:link,
    .sdds-nav__configurator-navigation a:visited,
    .sdds-nav__configurator-navigation a:active,
    .sdds-nav__configurator-navigation a:focus
    {
      color: var(--sdds-blue-700);
      font-size: 1.2em; font-weight: 600;
    }
    .sdds-nav__configurator-navigation a:hover
    {
      color: var(--sdds-blue-400);
    }

  .sdds-nav .sdds-nav__item:first-child {
    border: 0;
  }

  .sdds-divider-dark-vertical {
    height: 40px;
    width: 2px;
    display: inline-flex;
    margin: 0 7px;
  }
  .height-86 {
    height: 86px;
  }
  .sdds-nav__price .payment-container {
    position: relative;
  }
  .payment-container .paymentType-div {
    display: inline-flex;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 4px;
  }
  .paymentType-div .paymentType-option-div {
    display: inline-flex;
    width: 50%;
  }
  .paymentType-div .paymentType-option-div a.sdds-navigation-tabs--tab {
    padding: 5px 0px;
    flex: auto;
    cursor: pointer;
    text-align: center;
  }
  .paymentType-div .paymentType-option-div a.sdds-navigation-tabs--tab.sdds-navigation-tabs--tab__inactive {
    border: none;
  }
  .payment-container .sdds-divider-light {
    margin-bottom: 4px;
  }
  .sdds-nav__price .payment-container .payment-info.payment-info-leasing-padding {
    padding-top: 4px;
  }
  @media only screen and (max-width: 530px) {
    .sdds-nav__price .payment-info .payment-amount {
      font-size: 0.8em;
    }
  }
  @media only screen and (max-width: 500px) {
    .sdds-nav__configurator-navigation {
      width: unset;
    }
    .sdds-nav__price {
      width: 100%;
    }
    .payment-container {
      margin: 0 auto;
    }
    .sdds-nav__proceed.summary-container-responsive {
      position: absolute;
      z-index: 1000;
      right: var(--sdds-spacing-element-12);
      top: var(--sdds-spacing-layout-96);
      border-radius: 1rem;
      width: min-content;
      cursor: pointer;
      box-shadow: var(--sdds-white) 0px 0px 10px 10px;
      padding: 0;
    }
    .sdds-nav__proceed.summary-container-responsive.summary-container-responsive-top {
      top: calc(var(--sdds-spacing-layout-72) + 3rem);
    }
    .sdds-nav__proceed.summary-container-responsive > button {
      margin: 0;
      font-size: calc((14/ 4) * 0.9rem);
    }
  }
  @media only screen and (max-width: 450px) {
    .sdds-nav__price {
      padding: 0;
    }
  }
  @media only screen and (max-width: 410px) {
    .sdds-nav__price {
      padding: 0 var(--sdds-spacing-element-8);
    }
    .sdds-nav__price .payment-info .payment-amount {
      font-size: 0.6em;
    }
    .payment-info .sdds-detail-02 {
      font-size: calc(((14/ 4)) * 0.9rem);
    }
    .payment-info .priceSuffix__container {
      font-size: 0.36em;
    }
    .sdds-nav__proceed > button {
      padding: 0 0.8em;
      font-size: calc((14/ 4) * 0.9rem);
    }
  }
</style>

<script setup>
import { computed } from 'vue';
import { useMainStore } from '../store/index';


/* eslint-disable vue/no-deprecated-slot-attribute */ // Slot attribute used by SDDS
const store = useMainStore();

const emit = defineEmits(['search']);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  searchText: {
    type: String,
    default: '',
  },
});

const searchInput = computed({
  get() {
    return props.searchText;
  },
  set(value) {
    emit('search', value);
  },
});

const translatedSpecification = computed(() => {
  if (!Array.isArray(store.getModifiedSpecification) || store.getModifiedSpecification.length === 0) return [];
  return store.getModifiedSpecification.map(category => {
    const selections = category.selections.map(item => {
      const translation = store.fpcCodeTranslations.find(x => x.fpcCode === item.fpcCode);
      return {
        ...item,
        name: translation?.familyDescription || 'No translation found',
        value: translation?.variantDescription || 'No translation found',
      };
    });
    return { ...category, selections };
  });
});

const filterSelections = (selections => {
  return selections.filter(x =>
    !(x.fpcCode.endsWith('Z') || x.fpcCode.endsWith('-'))
    && Object.keys(x).some(key => x[key].toLowerCase().includes(searchInput.value.toLowerCase())));
});

</script>

<template>
  <sdds-modal
    size="md"
    :selector="`#${props.id}`"
  >
    <div
      slot="sdds-modal-headline"
      style="padding-right:1em;"
    >
      <h5>
        {{ $i18n('specification') }}
      </h5>
      <input
        v-model.trim="searchInput"
        class="sdds-textfield-input"
        type="text"
        autofocus
        :placeholder="$i18n('searchText')"
      >
    </div>
    <div slot="sdds-modal-body">
      <table
        v-if="translatedSpecification"
        class="sdds-table sdds-table--compact"
      >
        <template
          v-for="item in translatedSpecification"
          :key="item.id"
        >
          <thead class="sdds-table__header">
            <tr class="sdds-table__row">
              <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                {{ item.name }}
              </th>
              <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                &nbsp;
              </th>
              <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody
            v-for="subItem in filterSelections(item.selections)"
            :key="item.id+'-'+subItem.id"
            class="sdds-table__body"
          >
            <tr class="sdds-table__row">
              <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">
                {{ subItem.name }}
              </td>
              <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">
                {{ subItem.value }}
              </td>
              <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">
                {{ subItem.fpcCode }}
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
    <button
      slot="sdds-modal-actions"
      data-dismiss-modal
      class="sdds-btn sdds-btn-primary sdds-btn-md"
      data-dd-action-name="close"
    >
      {{ $i18n('closeButtonText') }}
    </button>
  </sdds-modal>
</template>

<style scoped>
  .sdds-table {
    width: 100%;
  }

  /*  FIX SDDS START */
  .sdds-table thead th {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .sdds-table thead:first-child th:first-child {
    border-top-left-radius: 4px;
  }
  .sdds-table thead:first-child th:last-child {
    border-top-right-radius: 4px;
  }
  .sdds-textfield-input {
    height: inherit;
  }
  /* FIX SDDS END */
</style>

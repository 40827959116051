import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { useMainStore } from './store/index';
import SummaryView from './views/SummaryView';
import ConfiguratorView from './views/ConfiguratorView';
import HomeView from './views/HomeView';
import ConfirmationView from './views/ConfirmationView';
import ActiveOffersView from './views/ActiveOffersView';
import { getOfferIds, getOfferStatus, getUserMetadata, checkFeatureToggle } from './services/digitalSalesService';


const requiredRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_ROLE;
const requiredSalesmanRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_SALESMAN_ROLE;

const isAuthorized = (app) => {
  return app.$keycloak.authenticated && (app.$keycloak.hasResourceRole(requiredRole) || app.$keycloak.hasResourceRole(requiredSalesmanRole));
};

const loadInitialData = async () => {
  const store = useMainStore();
  store.initialDataLoaded = true;
  store.appLoading = true;

  try {
    store.locale = store.getDefaultLocale;

    const initialLoad = await Promise.all([
      store.loadTranslations(),
      getOfferIds().catch(err => store.setOfferIds(false)),
      getUserMetadata(store.federationUserAccountId).catch(err => console.log('failed to get locale. using fallback instead')),
      checkFeatureToggle('webapp_show_availableOffersQuotationsPage_demo').catch(err => console.error('error fetching feature toggle :: ', err)),
      checkFeatureToggle('webapp_yearEndOfferBanner').catch(err => console.error('error fetching feature toggle :: ', err)),
      checkFeatureToggle('webapp_year2024OfferBanner').catch(err => console.error('error fetching feature toggle :: ', err)),
      checkFeatureToggle('webapp_show_ecolutionBlob').catch(err => console.error('error fetching feature toggle :: ', err)),
    ]);

    const offerIdsResponse = initialLoad[1];
    const metadataResponse = initialLoad[2];

    store.featureToggleData[`${store.market}#webapp_show_availableOffersQuotationsPage_demo`] = initialLoad[3]?.data;
    store.featureToggleData[`${store.market}#webapp_yearEndOfferBanner`] = initialLoad[4]?.data;
    store.featureToggleData[`${store.market}#webapp_year2024OfferBanner`] = initialLoad[5]?.data;
    store.featureToggleData[`${store.market}#webapp_show_ecolutionBlob`] = initialLoad[6]?.data;

    store.fallbackLocale = metadataResponse?.data?.locale || store.getDefaultLocale;
    if(metadataResponse?.data?.locale && store.locale !== metadataResponse?.data?.locale)
    {
      store.locale = metadataResponse?.data?.locale;
      await store.loadTranslations();
    }

    if (store.offerIds.error) {
      store.appLoading = false;
      return;
    }

    store.setOfferIds(offerIdsResponse.data || []);
    /* if(store.offerIds.data.length < 1) return; */
    if(store.offerIds.data.length < 1) {
      store.appLoading = false;
      return;
    }

    const statuses = [];
    await Promise.all(offerIdsResponse.data.map(async id => statuses.push({id: id, status: await (await getOfferStatus(id)).data})));
    store.offerStatuses = statuses;
  } catch (error) {
    console.error('loadInitialData', error);
  }
  store.appLoading = false;
};

const handleOfferAccess = async (app, to) => {
  const store = useMainStore();
  if(!store.initialDataLoaded) await loadInitialData();
  const id = to.query.id;
  const isNewOffer = !store.options || store.offerId !== id;
  if(!id || !isAuthorized(app) || (!store.offerIds.data.includes(id) && !store.isSalesman)) {
    return ({ name: 'Home', params: { market: store.market }, query: { id: id } });
  }
  store.setOfferId(id);
  let offerStatus = store.getOfferStatus;
  if (!offerStatus && store.isSalesman) {
    offerStatus = { id: store.offerId, status: await (await getOfferStatus(id)).data };
    store.offerStatuses.push(offerStatus);
  }
  if (!offerStatus.status.regulationsApprovedForUser && !store.isSalesman) {
    store.appLoading = false;
    return { name: 'Home', params: { market: store.market }, query: { id: store.offerId } };
  }
  if(isNewOffer) {
    store.appLoading = true;
    await store.loadConfiguration();
    store.appLoading = false;
  }
  if (offerStatus.status.finalized && to.name !== 'ConfirmationView') {
    return ({ name: 'ConfirmationView', params: { market: store.market }, query: { id: store.offerId } });
  }
  if (offerStatus.status.expired || offerStatus.status.isOfferNotValid) {
    store.appLoading = false;
    return { name: 'Home', params: { market: store.market }, query: { id: store.offerId } };
  }
};

const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
    routes: [
      {
        path: '/:market([a-z]{2})/',
        name: 'Home',
        component: HomeView,
        beforeEnter: async (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          if(!store.initialDataLoaded) await loadInitialData();
          if(!to.query.id && (store.activeOffers.length > 1 || store.isSalesman)) {
            return {
              name: 'ActiveOffersView',
              params: { market: store.market },
            };
          };
          store.setOfferId(to.query.id);
          let offerStatus = store.getOfferStatus;
          if (!offerStatus && store.isSalesman && store.offerId) {
            offerStatus = { id: store.offerId, status: await (await getOfferStatus(store.offerId)).data };
            store.offerStatuses.push(offerStatus);
          }
          if(store.offerId && ((store.offerIds.data.includes(store.offerId) && offerStatus.status.regulationsApprovedForUser) || store.isSalesman) && !offerStatus.status.expired && !offerStatus.status.isOfferNotValid) {
            return {
              name: 'ConfiguratorView',
              params: { market: store.market },
              query: {
                id: store.offerId,
              },
            };
          };
        },
      },
      {
        path: '/:market([a-z]{2})/active',
        name: 'ActiveOffersView',
        component: ActiveOffersView,
        beforeEnter: async (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          if(!store.initialDataLoaded) await loadInitialData();
          if(!isAuthorized(app) || (store.offerIds.data.length < 1 && !store.isSalesman)) {
            return ({ name: 'Home', params: { market: store.market } });
          }
        },
      },
      {
        path: '/:market([a-z]{2})/configure',
        name: 'ConfiguratorView',
        component: ConfiguratorView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/:market([a-z]{2})/summary',
        name: 'SummaryView',
        component: SummaryView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/:market([a-z]{2})/confirmation',
        name: 'ConfirmationView',
        component: ConfirmationView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/',
        name: 'Home-Default',
        component: HomeView,
        beforeEnter: async (to, from) => {
          const store = useMainStore();
          store.market = 'pl';
          if(!store.initialDataLoaded) await loadInitialData();
          if(!to.query.id && (store.activeOffers.length > 1 || store.isSalesman)) {
            return {
              name: 'ActiveOffersView',
              params: { market: store.market },
            };
          };
          store.setOfferId(to.query.id);
          let offerStatus = store.getOfferStatus;
          if (!offerStatus && store.isSalesman && store.offerId) {
            offerStatus = { id: store.offerId, status: await (await getOfferStatus(store.offerId)).data };
            store.offerStatuses.push(offerStatus);
          }
          if(store.offerId && ((store.offerIds.data.includes(store.offerId) && offerStatus.status.regulationsApprovedForUser) || store.isSalesman) && !offerStatus.status.expired && !offerStatus.status.isOfferNotValid) {
            return {
              name: 'ConfiguratorView',
              params: { market: store.market },
              query: {
                id: store.offerId,
              },
            };
          };
        },
      },
      {
        path: '/active',
        name: 'ActiveOffersView-Default',
        component: ActiveOffersView,
        beforeEnter: async (to, from) => {
          const store = useMainStore();
          store.market = 'pl';
          if(!store.initialDataLoaded) await loadInitialData();
          if(!isAuthorized(app) || (store.offerIds.data.length < 1 && !store.isSalesman)) {
            return ({ name: 'Home', params: { market: store.market } });
          }
        },
      },
      {
        path: '/configure',
        name: 'ConfiguratorView-Default',
        component: ConfiguratorView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = 'pl';
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/summary',
        name: 'SummaryView-Default',
        component: SummaryView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = 'pl';
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/confirmation',
        name: 'ConfirmationView-Default',
        component: ConfirmationView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = 'pl';
          return handleOfferAccess(app, to);
        },
      },
    ],
  });
  return router;
};

export {
  createRouter,
};

<script setup>
import { ref, inject } from 'vue';
import { approveRegulations } from '../services/digitalSalesService.js';
import MarkupSegment from './MarkupSegment';
import { addCustomTrackingEvent } from '../utils/customTracking';

const adobeEvent = inject('adobeEvent');

const emit = defineEmits(['approved']);

const error = ref(false);
const processing = ref(false);

const approve = async () => {
  adobeEvent({
    event: 'approvedRegulations',
  });
  addCustomTrackingEvent('approvedRegulations');
  processing.value = true;
  error.value = false;
  approveRegulations()
    .then(() => {
      emit('approved');
    })
    .catch(() => {
      error.value = true;
    })
    .finally(() => {
      processing.value = false;
    });
};

</script>

<template>
  <div
    v-if="error"
    class="sdds-banner sdds-banner-error margin-bottom-24"
  >
    <div class="sdds-banner-body">
      <h6 class="sdds-banner-header">
        {{ $i18n('approveRegulationsError') }}
      </h6>
    </div>

    <div
      class="sdds-banner-close"
      @click="error=false"
    />
  </div>
  <h1>{{ $i18n('approveRegulationsHeading') }}</h1>
  <MarkupSegment
    class="regulatoryText"
    :text="$i18n('approveRegulationsText')"
  />
  <p>
    {{ $i18n('agreeTerms') }}
  </p>
  <button
    class="sdds-btn sdds-btn-primary sdds-btn-md"
    :disabled="processing"
    @click="approve()"
  >
    {{ $i18n('approveRegulationsButtonText') }}
    <font-awesome-icon
      v-if="processing"
      icon="spinner"
      class="fa-spin"
    />
  </button>
</template>

<style scoped>
  .regulatoryText {
    flex: 0 0 70rem;
    max-width: 180rem;
    overflow-y: scroll;
    background-color: var(--sdds-white);
    border-radius: 1rem;
    padding: var(--sdds-spacing-element-8);
    margin-bottom: var(--sdds-spacing-layout-24);
    font-size: 3rem;
    word-break: break-all;
  }
</style>

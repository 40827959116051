<script setup>
import { computed } from 'vue';
const emit = defineEmits(['update:selection']);
const props = defineProps({
  selection: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => {
      return [];
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'large',
  },
  label: {
    type: String,
    default: '',
  },
  optionKey: {
    type: String,
    default: null,
  },
  valueKey: {
    type: String,
    default: null,
  },
});

const selectionTemp = computed ({
  get() { return props.selection; },
  set(newVal) { emit('update:selection', newVal );},
});
</script>

<template>
  <div :class="`sdds-dropdown sdds-dropdown-${size}`">
    <span
      v-if="label"
      class="sdds-dropdown-label-outside"
    >{{ label }}</span>
    <select
      v-model="selectionTemp"
      :disabled="disabled"
    >
      <option
        :value="null"
        disabled
      >
        Select...
      </option>
      <option
        v-for="option in options"
        :key="option"
        :value="valueKey ? option[valueKey] : option"
      >
        {{ optionKey ? option[optionKey] : option }}
      </option>
    </select>
  </div>
</template>

<style scoped>
  .sdds-dropdown select:disabled:hover {
    border-bottom-color: var(--sdds-grey-400);
    cursor: not-allowed;
  }
</style>

<script setup>
import { computed } from 'vue';
import ConfigurationRadioSelection from '../../ConfigurationRadioSelection';
import ConfigurationCheckSelection from '../../ConfigurationCheckSelection';
import SelectOptionInfo from '../../SelectOptionInfo';
import { useMainStore } from '../../../store/index.js';

import '../../../styles/configurator-section.css';
import '../../../styles/configurator-selection.css';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    default: null,
  },
});

const interiorstylingPackage = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'interiorstylingPackage');
});
const infotainmentPackage = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'infotainmentPackage');
});
const interactiveCentreInfoDisplay = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'interactiveCentreInfoDisplay');
});
const comfortPackage = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'comfortPackage');
});
const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});
</script>

<template>
  <section
    v-if="props.items"
    id="styling-comfort"
    class="configurator-section"
  >
    <h2>{{ $i18n('stylingComfortHeaderTitle') }}</h2>
    <template v-if="specType !== 'stock_vehicle'">
      <ConfigurationRadioSelection
        v-if="interiorstylingPackage"
        v-model="store.selected.stylingComfort[interiorstylingPackage.id]"
        :type="interiorstylingPackage.id"
        :options="interiorstylingPackage.options"
        @select="emit('select', $event)"
      />
    </template>
    <template v-if="specType === 'stock_vehicle'">
      <div class="configurator-selection">
        <div
          class="configurator-selection__options"
          :class="{ 'justify-content-center': true }"
        >
          <SelectOptionInfo
            v-if="interiorstylingPackage"
            :option="interiorstylingPackage.options[0]"
            :selected="true"
            :show-gap-above-description="true"
          />
        </div>
      </div>
    </template>
    <div class="configurator-section__image">
      <img
        :src="props.images?.dashboard"
        alt="Truck - front side view"
      >
      <img
        :src="props.images?.interior"
        alt="Truck - front side view"
      >
    </div>
    <h3>{{ $i18n('extras') }}</h3>
    <template v-if="specType !== 'stock_vehicle'">
      <div class="configurator-section__row">
        <ConfigurationCheckSelection
          v-if="infotainmentPackage"
          v-model="store.selected.stylingComfort[infotainmentPackage.id]"
          :type="infotainmentPackage.id"
          :options="infotainmentPackage.options"
          @select="emit('select', $event)"
        />
        <ConfigurationCheckSelection
          v-else-if="interactiveCentreInfoDisplay"
          v-model="store.selected.stylingComfort[interactiveCentreInfoDisplay.id]"
          :type="interactiveCentreInfoDisplay.id"
          :options="interactiveCentreInfoDisplay.options"
          @select="emit('select', $event)"
        />
        <template v-if="comfortPackage">
          <div class="sdds-divider-dark-vertical" />
          <ConfigurationCheckSelection
            v-model="store.selected.stylingComfort[comfortPackage.id]"
            :type="comfortPackage.id"
            :options="comfortPackage.options"
            @select="emit('select', $event)"
          />
        </template>
      </div>
    </template>
    <template v-if="specType === 'stock_vehicle'">
      <div class="configurator-section__row">
        <div class="configurator-selection">
          <div class="configurator-selection__checkoption">
            <SelectOptionInfo
              v-if="infotainmentPackage"
              :option="infotainmentPackage.options[0]"
              :checkbox="infotainmentPackage.selectionType === 'checkbox'"
              :selected="true"
              :show-gap-above-description="true"
            />
            <SelectOptionInfo
              v-else-if="interactiveCentreInfoDisplay"
              :option="interactiveCentreInfoDisplay.options[0]"
              :checkbox="interactiveCentreInfoDisplay.selectionType === 'checkbox'"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
        <template v-if="comfortPackage">
          <div class="sdds-divider-dark-vertical" />
          <div class="configurator-selection">
            <div class="configurator-selection__checkoption">
              <SelectOptionInfo
                :option="comfortPackage.options[0]"
                :checkbox="comfortPackage.selectionType === 'checkbox'"
                :selected="true"
                :show-gap-above-description="true"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<style scoped>
  .configurator-section__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
    max-width: 100%;
  }
  .configurator-section__image img {
    width: 40%;
  }
  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);
    align-self: center;
  }
  .configurator-selection__checkoption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 30em;
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 1000px) {
    .configurator-section__image {
      gap: 1em;
      max-width: 90%;
    }
    .sdds-divider-dark-vertical {
      display: none;
    }
    .configurator-section__row {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 500px) {
    .configurator-section__image {
      flex-direction: column;
      align-items: center;
    }
    .configurator-section__image img {
      width: 90%;
    }
  }
</style>

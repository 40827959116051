<script setup>
  import { computed, inject } from 'vue';

  const i18n = inject('i18n');

  const props = defineProps({
    priceSuffix: {
      type: String,
      required: true,
    },
    inlineBlock: {
      type: Boolean,
      default: false,
    },
  });
  const suffix = computed(() => {
    if (!props.priceSuffix) return { price: '', text: '' };
    const struct = i18n(props.priceSuffix).split('/');
    return {
      price: struct[0],
      text: struct[1],
    };
  });
</script>

<template>
  <span class="priceSuffix__container">
    <span
      class="priceSuffix__component"
      :class="{ 'sdds-u-inline-block': inlineBlock }"
    >{{ suffix.price }}</span>
    <span
      v-if="suffix.text"
      class="priceSuffix__component"
      :class="{ 'sdds-u-inline-block': inlineBlock }"
    >/{{ suffix.text }}</span>
  </span>
</template>

<style scoped>
  .priceSuffix__container {
    display: inline-block;
  }
  .priceSuffix__component {
    display: block;
  }
</style>

<script setup>
import { computed, inject } from 'vue';
import ConfigurationRadioSelection from '../ConfigurationRadioSelection';
import ConfigurationSliderSelection from '../ConfigurationSliderSelection';
import SelectOptionInfo from '../SelectOptionInfo';
import { useMainStore } from '../../store/index.js';

import '../../styles/configurator-section.css';

const i18n = inject('i18n');

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
});

const engines = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'engineType');
});

const rearAxleGearRatios = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'rearAxleGearRatio');
});

const rearAxleGearRatiosOptions = computed(() => {
  if (rearAxleGearRatios.value?.options) {
    return rearAxleGearRatios.value.options?.map(o => {
      o.description = i18n(o.value);
      o.value = i18n(rearAxleGearRatios.value.title);
      return o;
    });
  } else return [];
});

const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});
</script>

<template>
  <section
    v-if="props.items"
    id="powertrain"
    class="configurator-section"
  >
    <h2>{{ $i18n('drivelineHeaderTitle') }}</h2>
    <template v-if="specType !== 'stock_vehicle'">
      <ConfigurationRadioSelection
        v-if="engines"
        v-model="store.selected.driveline[engines.id]"
        :type="engines.id"
        :options="engines.options"
        @select="emit('select', $event)"
      />
      <ConfigurationSliderSelection
        v-if="rearAxleGearRatios"
        v-model="store.selected.driveline[rearAxleGearRatios.id]"
        :title="rearAxleGearRatios.title"
        :type="rearAxleGearRatios.id"
        :options="rearAxleGearRatios.options"
        :show-slider-extreme-values="true"
        :show-tooltip="false"
        :show-information="true"
        @select="emit('select', $event)"
      />
    </template>
    <template v-if="specType === 'stock_vehicle'">
      <div class="configurator-section__row">
        <div class="configurator-selection">
          <div
            class="configurator-selection__options"
            :class="{ 'justify-content-center': true }"
          >
            <SelectOptionInfo
              :option="engines.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
        <div class="sdds-divider-dark-vertical" />
        <div class="configurator-selection">
          <div
            class="configurator-selection__options sdds-u-h-100"
            :class="{ 'justify-content-center': true }"
          >
            <SelectOptionInfo
              :option="rearAxleGearRatiosOptions[0]"
              :selected="true"
              :show-description-text-as-value="true"
            />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<style scoped>
  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);
    align-self: center;
  }

  @media only screen and (max-width: 1000px) {
    .sdds-divider-dark-vertical {
      display: none;
    }
    .configurator-section__row {
      flex-direction: column;
    }
  }
</style>

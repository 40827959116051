<script setup>
import { computed } from 'vue';
import PowertrainSection from '@/components/configuratorSections/PowertrainSection';
import CabColorSection from '@/components/configuratorSections/CabColorSection';
// import StylingAndComfortSection from '@/components/configuratorSections/StylingAndComfortSection';
import StylingAndComfortSectionV2 from '@/components/configuratorSections/StylingAndComfortSectionV2';
// import ChassisSection from '@/components/configuratorSections/ChassisSection';
import ChassisSectionV2 from '@/components/configuratorSections/ChassisSectionV2';
import FifthWheelSection from '@/components/configuratorSections/FifthWheelSection';
import FuelEfficiencySection from '@/components/configuratorSections/FuelEfficiencySection';
import ConnectedServicesSection from '@/components/configuratorSections/ConnectedServicesSection';
import SafetySectionGroup from '@/components/configuratorSections/SafetySectionGroup';
import RepairMaintenanceSection from '@/components/configuratorSections/RepairMaintenanceSection';

import { useMainStore } from '@/store/index.js';

const store = useMainStore();

defineProps({
  title: {
    type: String,
    default: '',
  },
  offerTemplate: {
    type: String,
    default: 'PL_STANDARDA4X2NA',
    required: true,
  },
  specType: {
    type: String,
    default: '',
    required: true,
  },
  isFrameAgreement: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const emit = defineEmits(['select']);

const options = computed(() => {
  return store.options;
});

const images = computed(() => {
  return store.images;
});

const interiorImages = computed(() => {
  return { interior: images.value.interior, dashboard: images.value.dashboard };
});

</script>
<template>
  <PowertrainSection
    :items="options.driveline"
    @select="emit('select', $event, 'driveline')"
  />
  <CabColorSection
    :items="options.cabColour"
    :front-image="images?.front"
    @select="emit('select', $event, 'cabColour')"
  />
  <!-- <StylingAndComfortSection
    :items="options.stylingComfort"
    :images="interiorImages"
    @select="emit('select', $event, 'stylingComfort')"
  /> -->
  <StylingAndComfortSectionV2
    :items="options.stylingComfort"
    :images="interiorImages"
    :offer-template="offerTemplate"
    :spec-type="specType"
    :is-frame-agreement="isFrameAgreement"
    @select="emit('select', $event, 'stylingComfort')"
  />
  <!-- <ChassisSection
    :items="options.chassis"
    :images="{ side: images?.side }"
    class="chasis-section"
    @select="emit('select', $event, 'chassis')"
  /> -->
  <ChassisSectionV2
    :items="options.chassis"
    :images="{ side: images?.side }"
    :offer-template="offerTemplate"
    :spec-type="specType"
    :is-frame-agreement="isFrameAgreement"
    class="chasis-section"
    @select="emit('select', $event, 'chassis')"
  />
  <FifthWheelSection
    :items="options.fifthWheel"
    :images="interiorImages"
    @select="emit('select', $event, 'fifthWheel')"
  />
  <SafetySectionGroup
    :items="options.safety"
    @select="emit('select', $event, 'safety')"
  />
  <FuelEfficiencySection
    :items="options.fuelEfficiency"
    :images="{ view: images?.view }"
    @select="emit('select', $event, 'fuelEfficiency')"
  />
  <ConnectedServicesSection
    :items="options.connectedServices"
    @select="emit('select', $event, 'connectedServices')"
  />
  <!-- <DriverServicesSection
          :items="options.driverServices"
          @select="handleOptionSelect($event, 'driverServices')"
        /> -->
  <RepairMaintenanceSection
    :items="options.repairMaintenance"
    @select="emit('select', $event, 'repairMaintenance')"
  />
</template>


<style scoped>
.chasis-section >>> .configurator-section__image > img {
  max-width: 70%;
}

@media only screen and (max-width: 1294px) {
  .chasis-section >>> .configurator-section__image > img {
      max-width: 80%;
    }
}

@media only screen and (max-width: 1000px) {
  .chasis-section >>> .configurator-section__image > img {
      max-width: 90%;
    }
}

@media only screen and (max-width: 600px) {
  .chasis-section >>> .configurator-section__image > img {
    max-width: 120%;
  }
}
</style>

<script setup>
  import { inject, computed } from 'vue';

  const i18n = inject('i18n');

  const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;
  const imageUrl = computed(() => {
    const image = (i18n('imageUrlContactInfo') !== 'imageUrlContactInfo') ? i18n('imageUrlContactInfo') : 'contact_info.png';
    return`${staticImagesBaseUrl}/${image}`;
  });
</script>

<template>
  <div class="contact">
    <div class="contact__image">
      <img :src="imageUrl">
    </div>
    <div class="contact__details">
      <ol>
        <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <path
              d="M18,54v148h220V54H18z M218.34,64l-89.12,74.71L40.11,64H218.34z M28,66.9l73.89,61.95L28,186.92V66.9z
	 M37.71,192l72-56.59l19.51,16.35l19.94-16.72L221.63,192H37.71z M228,184.29l-71.01-55.81L228,68.95V184.29z"
            />
          </svg>
          {{ $i18n('confirmationViewContactEmail') }}
        </li>
        <li>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.62 4c.908 0 1.703.61 1.938 1.487L14.61 9.42a2.005 2.005 0 0 1-.52 1.937l-.594.595v.001l-.92.92a14.402 14.402 0 0 0 2.74 3.813 14.403 14.403 0 0 0 3.813 2.739l.672-.672-.005-.005.845-.844a2.005 2.005 0 0 1 1.936-.52l3.934 1.054a2.005 2.005 0 0 1 1.486 1.938l-.001 2.404H28v3.232c0 1.042-.813 1.955-1.912 1.982a21.505 21.505 0 0 1-15.772-6.31 21.504 21.504 0 0 1-6.31-15.771C4.033 4.813 4.946 4 5.987 4H9.22v.002L11.62 4ZM8.124 6h-2.12a19.504 19.504 0 0 0 5.726 14.27A19.505 19.505 0 0 0 26 25.995v-2.124h-.004l.002-3.496a.005.005 0 0 0-.004-.005l-3.933-1.053-.005.001-.597.596.005.005-.93.93a1.965 1.965 0 0 1-2.253.387 16.402 16.402 0 0 1-4.379-3.136 16.401 16.401 0 0 1-3.135-4.378 1.965 1.965 0 0 1 .387-2.254l.68-.68v-.001l.844-.845a.005.005 0 0 0 0-.005l-1.052-3.933A.005.005 0 0 0 11.62 6l-3.497.002V6Z"
              fill="currentColor"
            />
          </svg>
          {{ $i18n('confirmationViewContactPhone') }}
        </li>
      </ol>
    </div>
  </div>
</template>

<style scoped>
  .contact {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 2em;
  }
  .contact__image {
    display: flex;
    place-content: center;
  }
  .contact__image img {
    width: 75%;
    border-radius: 50%;
  }
  .contact__details {
    display: flex;
    place-items: center;
  }
  .contact__details svg {
    width: 2em;
    margin-right: 1em;
    vertical-align: middle;
  }
  .contact__details > ol {
    list-style-type: none;
    padding-inline-start: 0px;
  }
  .contact__details > ol > li {
    font-size: 1.2em;
    line-height: 1.8em;
    margin-bottom: 1em;
  }
</style>

<script setup>
import { computed } from 'vue';
import { useMainStore } from '../../../store/index.js';
import ColorOption from './ColorOption';
import ColorOptionInfo from './ColorOptionInfo';
import '../../../styles/configurator-section.css';
import '../../../styles/configurator-selection.css';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  frontImage: {
    type: String,
    required: true,
  },
  colorHeaderTitle: {
    type: String,
    required: false,
    default: 'cabColourHeaderTitle',
  },
  colorSubHeaderTitle: {
    type: String,
    required: false,
    default: '',
  },
});

const selectedColor = computed(() => {
  return store.selected.cabColour.cabColour;
});

const handleSelect = (option) => {
  store.selected.cabColour.cabColour = option;
  emit('select', { option, type: 'cabColour' });
};

const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});
</script>

<template>
  <section
    v-if="props.items"
    id="cab-color"
    class="configurator-section"
  >
    <h2>{{ $i18n(props.colorHeaderTitle) }}</h2>
    <div class="configurator-section__cab-color">
      <div class="configurator-section__image">
        <img
          :src="props.frontImage"
          alt="Truck - front side view"
        >
      </div>
      <div class="color-selection-container">
        <h3 v-if="props.colorSubHeaderTitle">
          {{ $i18n(props.colorSubHeaderTitle) }}
        </h3>
        <div class="configurator-selection">
          <template v-if="specType !== 'stock_vehicle'">
            <ColorOption
              v-for="item in props.items[0].options"
              :key="item.id"
              :option="item"
              :selected="selectedColor.id === item.id"
              :selected-payment-type="store.selectedPaymentType"
              @select="handleSelect"
            />
          </template>
          <template v-if="specType === 'stock_vehicle'">
            <ColorOptionInfo
              v-for="item in props.items[0].options"
              :key="item.id"
              :option="item"
              :selected="selectedColor.id === item.id"
              @select="handleSelect"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .configurator-section__cab-color {
    display: flex;
    flex-direction: row;
    place-content: center;
  }
  .configurator-section__image {
    display: flex;
    place-content: center;
    width: 60%;
  }
    .configurator-section__image img {
      max-width: 100%;
      object-fit: contain;
    }

  .configurator-selection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: var(--sdds-spacing-element-32);
    width: 50%;
    padding-top: var(--sdds-spacing-element-48);
  }
  .color-selection-container {
    display: flex;
    place-content: center;
    flex-direction: column;
    width: 40%;
    align-items: center;
  }

  .color-selection-container > h3 {
    text-align: center;
  }

  @media only screen and (max-width: 1294px) {
    .configurator-section__cab-color {
      flex-direction: column;
      align-items: center;
    }
    .configurator-selection {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: var(--sdds-spacing-element-24);
      width: 100vw;
    }
  }

</style>

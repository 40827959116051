<script setup>
import { computed } from 'vue';
import SddsIconCustom from './SddsIconCustom.vue';

const props = defineProps(
  {
    quotationId: {
      type: String,
      default: '',
    },
    quotationLink: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    keypoints: {
      type: Array,
      default () {
        return [];
      },
    },
    buttonText: {
      type: String,
      default: '',
    },
    additionalInfo: {
      type: String,
      default: '',
    },
    quotationStatus: {
      type: Object,
      default() {
        return {};
      },
    },
    quotationData: {
      type: Object,
      default() {
        return {};
      },
    },
    cardHeaderChips: {
      type: Object,
      default() {
        return {};
      },
    },
  },
);

const showCardHeader = computed(() => {
  return (props.cardHeaderChips?.specialOffer || props.cardHeaderChips?.ecolution) ||
    (props.quotationStatus?.expired || props.quotationStatus?.finalized || props.quotationStatus?.isQuotationNotValid);
});
</script>
<template>
  <div class="card-container">
    <!-- <template v-if="showCardHeader">
      <div class="card-header">
        <template v-if="quotationStatus?.expired">
          <div class="sdds-chip sdds-chip__icon-right card-header-chip-with-icon">
            <SddsIconCustom name="clock" />
            <span class="sdds-chip-text">{{ $i18n('quotationExpired') }}</span>
          </div>
        </template>
        <template v-if="quotationStatus?.isQuotationNotValid">
          <div class="sdds-chip sdds-chip__icon-right card-header-chip-with-icon">
            <SddsIconCustom />
            <span class="sdds-chip-text">{{ $i18n('quotationNotValid') }}</span>
          </div>
        </template>
        <template v-if="!quotationStatus?.expired && !quotationStatus?.isQuotationNotValid && !quotationStatus?.finalized && cardHeaderChips?.specialOffer">
          <div class="sdds-chip card-header-chip chip-special-offer">
            <span class="sdds-chip-text">{{ $i18n('availableOffersSpecialOffer') }}</span>
          </div>
        </template>
        <template v-if="!quotationStatus?.expired && !quotationStatus?.isQuotationNotValid && !quotationStatus?.finalized && cardHeaderChips?.ecolution">
          <div class="sdds-chip card-header-chip chip-ecolution">
            <span class="sdds-chip-text">{{ $i18n('availableOffersEcolution') }}</span>
          </div>
        </template>
      </div>
    </template> -->
    <div class="card-top">
      <img
        :src="imageUrl"
        alt="Card Image"
        class="card-img"
      >
    </div>
    <div
      class="card"
      :class="{ 'card-disabled': quotationStatus?.expired || quotationStatus?.finalized || quotationStatus?.isQuotationNotValid, 'sdds-u-pt0': showCardHeader }"
    >
      <div class="card-body">
        <p class="sdds-headline-03">
          {{ $i18n(title) }}
        </p>
        <p class="sdds-headline-05 sdds-u-mt0">
          {{ $i18n('availableOffersQuotationId') }}: {{ $i18n(quotationId) }}
        </p>
        <ul class="card-keypoints">
          <li
            v-for="keypoint in keypoints"
            :key="keypoint.icon"
          >
            <div class="sdds-row">
              <div class="sdds-col keypoint-icon">
                <SddsIconCustom :name="keypoint.icon" />
              </div>
              <div class="sdds-col keypoint-info">
                {{ $i18n(keypoint.translationKey) }}
              </div>
            </div>
          </li>
        </ul>
        <!-- <template v-if="quotationStatus?.expired || quotationStatus?.finalized || quotationStatus?.isQuotationNotValid">
          <button
            class="sdds-btn sdds-btn-primary sdds-btn-fullbleed"
            disabled
          >
            {{ $i18n(buttonText) }} <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.958 26.314a1 1 0 1 0 1.415 1.414l9.89-9.89a2.6 2.6 0 0 0 0-3.676l-9.89-9.89a1 1 0 1 0-1.415 1.414L27.273 15H2.976a1 1 0 1 0 0 2h24.296l-9.314 9.314Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <div
            v-if="quotationStatus?.expired"
            class="sdds-u-pt1"
          >
            {{ $i18n('quotationExpired') }} 
            <template v-if="quotationData?.quotationValidDate && quotationData?.quotationValidDate.length && quotationData?.quotationValidDate[0]">
              ({{ quotationData?.quotationValidDate }})
            </template>
          </div>
          <div
            v-if="quotationStatus?.finalized"
            class="sdds-u-pt1"
          >
            {{ $i18n('quotationConfirmationViewHeading') }}
          </div>
          <div
            v-if="quotationStatus?.isQuotationNotValid"
            class="sdds-u-pt1"
          >
            {{ $i18n('quotationNotValid') }}
          </div>
        </template>
        <template v-else> -->
        <a
          class="sdds-btn sdds-btn-primary sdds-btn-fullbleed"
          :href="$i18n(quotationLink)"
        >
          {{ $i18n(buttonText) }} <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.958 26.314a1 1 0 1 0 1.415 1.414l9.89-9.89a2.6 2.6 0 0 0 0-3.676l-9.89-9.89a1 1 0 1 0-1.415 1.414L27.273 15H2.976a1 1 0 1 0 0 2h24.296l-9.314 9.314Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <!-- <div
          v-if="quotationData?.quotationValidDate && quotationData?.quotationValidDate.length && quotationData?.quotationValidDate[0]"
          class="sdds-u-pt1"
        >
          {{ $i18n('quotationValidPeriod', quotationData?.quotationValidDate) }}
        </div> -->
        <!-- </template> -->
      </div>
    </div>
    <div
      v-if="props.additionalInfo"
      class="card-accordion"
    >
      <sdds-accordion>
        <sdds-accordion-item :header="$i18n('moreInformationText')">
          {{ $i18n(props.additionalInfo) }}
        </sdds-accordion-item>
      </sdds-accordion>
    </div>
  </div>
</template>

<style scoped>
.sdds-btn-fullbleed {
  width: fit-content;
}
.card-container {
  margin-top: var(--sdds-spacing-layout-48);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  background-image: linear-gradient(#A9A9A9, var(--sdds-grey-50), var(--sdds-grey-50));
}
.card-header {
  background-color: #A9A9A9;
  width: 100%;
  padding: var(--sdds-spacing-element-16);
  padding-bottom: var(--sdds-spacing-element-8);
}
.card-header .sdds-chip {
  cursor: default;
}
.card-header .card-header-chip-with-icon object {
  height: 16px;
  width: 16px;
  margin-right: 0;
  position: absolute;
  right: 8px;
}
.card-header .card-header-chip.chip-special-offer {
  background-color: #DA2B40;
}
.card-header .card-header-chip.chip-ecolution {
  background-color: #D95610;
}
.card-header .card-header-chip span.sdds-chip-text {
  color: #fff;
}

.card-top {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#A9A9A9, var(--sdds-grey-50), var(--sdds-grey-50));
}


.card {
  display: flex;
  flex-direction: column;
  background-color: var(--sdds-grey-50);
}

.card-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.card-img {
  height: auto;
  object-fit: cover;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: var(--sdds-spacing-element-20) var(--sdds-spacing-element-20);
  background-color: var(--sdds-grey-50);
}

.card-accordion{
  background-color: var(--sdds-grey-50);
}

.card-body > button, .card-body > a {
  display: flex;
  place-content: center;
  align-items: center;
  margin-top: auto;
  color: white;
}
.card-body > button > svg, .card-body > a > svg {
  line-height: 0;
  margin-left: var(--sdds-spacing-element-12);
  height: 6rem;
  width: 6rem;
}

.card-keypoints {
  list-style: none;
  margin: auto 0;
  padding: 0;
}

.card-keypoints li {
  margin-bottom: var(--sdds-spacing-layout-8);
}

.sdds-headline-03 {
  margin: unset;
  margin-bottom: var(--sdds-spacing-element-12);
}

.card-keypoints li .keypoint-icon {
  max-width: fit-content;
  padding: 0;
}

.card-keypoints li .keypoint-info {
  line-height: calc(((24 / 4))* 1rem);
  padding: 0 0 0 4px;
}

.card-additional-info{
  margin-top: var(--sdds-spacing-element-4);
}

@media only screen and (max-width: 1024px) {
  .active-offers__card-list > * {
    width: 100%;
    margin: unset;
  }
}

@media only screen and (max-width: 768px) {
  .card {
    flex-direction: column;
  }
  .card-img {
    width: 100%;
  }
  .card-body {
    margin-top: var(--sdds-spacing-element-16);
    width: 100%;
  }
}
</style>

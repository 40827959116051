<script setup>
import { useMainStore } from '../store/index.js';
import SimpleSlider from './SimpleSlider';
import MonthlyPriceTooltip from './MonthlyPriceTooltip';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  header: {
    type: String,
    default: '',
  },
  headerHelperText: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  showSliderTooltipValue: {
    type: Boolean,
    default: false,
  },
  sliderTooltipValue: {
    type: String,
    default: '',
  },
  sliderData: {
    type: Object,
    default () {
      return null;
    },
  },
  monthlyPriceTooltipId: {
    type: String,
    default: '',
  },
  customDescription: {
    type: Boolean,
    default: false,
  },
  additionalDescription: {
    type: String,
    default: null,
  },
});

</script>
<template>
  <div class="general-container">
    <div class="flex-container">
      <h5
        v-if="header !== ''"
        class="flex-grow sdds-u-mt1"
      >
        {{ $i18n(header) }}
        <template v-if="headerHelperText !== ''">
          ({{ $i18n(headerHelperText) }})
        </template>
      </h5>
    </div>
    <div class="flex-container">
      <div class="item-description">
        <template v-if="customDescription">
          <span class="year-end-offer-price">
            {{ String($i18n(description)).split('#')[0] }}
          </span> {{ String($i18n(description)).split('#')[1] }}
        </template>
        <template v-else>
          {{ $i18n(description) }}
        </template>
        <span
          v-if="showSliderTooltipValue"
          class="sdds-detail-07"
        >({{ sliderTooltipValue }})</span>
        <span
          v-if="header === 'monthlyCostForServices' && monthlyPriceTooltipId !== ''"
          class="el-margin-left-4"
        ><MonthlyPriceTooltip
          :selector-id="monthlyPriceTooltipId"
          :include-selected-vehicles="true"
        /></span>
        <template v-if="customDescription && additionalDescription">
          <br><span style="font-size: x-small;">{{ $i18n(additionalDescription) }}</span>
        </template>
      </div>
      <div class="flex-grow">
        <SimpleSlider
          v-if="sliderData"
          v-model="store.selected.leasing[sliderData.id]"
          :type="sliderData.id"
          :options="sliderData.options"
          :show-tooltip="false"
          value-property="description"
          @select="emit('select', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
  h5 {
    margin-bottom: var(--sdds-spacing-element-0);
    font-weight: 500;
  }
  .flex-container {
    display: flex;
    align-items: center;
  }
  .flex-container > div {
    margin-right: var(--sdds-spacing-element-8);
  }
  .flex-container > div:nth-child(2) {
    margin-right: var(--sdds-spacing-element-48);
  }
  .flex-grow {
    flex-grow: 1;
  }
  .general-container{
    margin-bottom: var(--sdds-spacing-element-8);
  }
</style>

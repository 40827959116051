<script setup>

const emit = defineEmits(['click']);
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  selectedOptions: {
    type: Array,
    required: true,
  },
  idField: {
    type: String,
    default: 'id',
  },
  nameField: {
    type: String,
    default: 'name',
  },
});

</script>

<template>
  <template
    v-for="(option, key) in options"
    :key="key"
  >
    <div
      class="sdds-chip sdds-chip__icon-right sdds-u-pr2 sdds-u-mr2"
      :class="props.selectedOptions.includes(option[props.idField]) ? 'selected-filter' : ''"
      @click="emit('click', option[props.idField])"
    >
      <template v-if="props.selectedOptions.includes(option[props.idField])">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          class="selected-filter-icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.793 4.293a1 1 0 0 1 1.414 0L16.5 14.586 26.793 4.293a1 1 0 1 1 1.414 1.414L17.914 16l10.293 10.293a1 1 0 0 1-1.414 1.414L16.5 17.414 6.207 27.707a1 1 0 0 1-1.414-1.414L15.086 16 4.793 5.707a1 1 0 0 1 0-1.414Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <template v-else>
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          class="selected-filter-icon"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17 4.98a1 1 0 1 0-2 0V15H4.98a1 1 0 0 0 0 2H15v10.02a1 1 0 0 0 2 0V17h10.02a1 1 0 1 0 0-2H17V4.98Z"
            fill="currentColor"
          />
        </svg>
      </template>
      <span class="sdds-chip-text">{{ $i18n(option[props.nameField]) }}</span>
    </div>
  </template>
</template>

<style scoped>
  .selected-filter {
    background: #37414F;
    color: white;
  }

  .selected-filter-icon {
    height: 16px;
    width: 16px;
    margin-left: 8px;
    margin-right: 0px;
  }
</style>

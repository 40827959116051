<!--
================================================================
     ██╗███████╗    ███████╗ ██████╗██████╗ ██╗██████╗ ████████╗
     ██║██╔════╝    ██╔════╝██╔════╝██╔══██╗██║██╔══██╗╚══██╔══╝
     ██║███████╗    ███████╗██║     ██████╔╝██║██████╔╝   ██║   
██   ██║╚════██║    ╚════██║██║     ██╔══██╗██║██╔═══╝    ██║   
╚█████╔╝███████║    ███████║╚██████╗██║  ██║██║██║        ██║   
 ╚════╝ ╚══════╝    ╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝╚═╝        ╚═╝   
================================================================
-->
<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useMainStore } from '../store/index';
  import InformationModal from './InformationModal';

  const store = useMainStore();

  const imageInformation = {
    title: 'images',
    text: 'imageDisclaimerText',
  };

  const regulationsModal = computed(() => {
    return {
      title: 'serviceRegulation',
      text: store.selectedPaymentType === 'cash' ? 'approveRegulationsCashText' : 'approveRegulationsText',
    };
  });
  const showPriceDisclaimer = computed(() => {
    return useRoute()?.name === 'SummaryView';
  });

  const euriborDate = computed(() => {
    return [store.misc?.euriborDate];
  });
</script>

<!--
=============================================================================================================
██╗  ██╗████████╗███╗   ███╗██╗         ████████╗███████╗███╗   ███╗██████╗ ██╗      █████╗ ████████╗███████╗
██║  ██║╚══██╔══╝████╗ ████║██║         ╚══██╔══╝██╔════╝████╗ ████║██╔══██╗██║     ██╔══██╗╚══██╔══╝██╔════╝
███████║   ██║   ██╔████╔██║██║            ██║   █████╗  ██╔████╔██║██████╔╝██║     ███████║   ██║   █████╗  
██╔══██║   ██║   ██║╚██╔╝██║██║            ██║   ██╔══╝  ██║╚██╔╝██║██╔═══╝ ██║     ██╔══██║   ██║   ██╔══╝  
██║  ██║   ██║   ██║ ╚═╝ ██║███████╗       ██║   ███████╗██║ ╚═╝ ██║██║     ███████╗██║  ██║   ██║   ███████╗
╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝       ╚═╝   ╚══════╝╚═╝     ╚═╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
=============================================================================================================
-->
<template>
  <footer class="sdds-footer">
    <div class="sdds-footer-main">
      <ul class="sdds-footer-main-links">
        <li>
          <a
            id="regulationsModal"
            href="#"
            data-dd-action-name="regulations modal"
            @click.prevent
          >{{ $i18n('serviceRegulation') }}</a>
        </li>
        <li
          v-for="link in store.footerLinkMetadataForActiveMarket"
          :key="link.page"
        >
          <a
            :href="link.link"
            target="_blank"
          >
            {{ $i18n(link.title) }}
          </a>
        </li>
        <li>
          <div
            id="imagesModalId"
          >
            <a
              data-analytics="{'eventComponent':'footer','eventType':'modal','eventLabel':'images information modal'}"
              href="#"
              data-dd-action-name="images information modal"
              @click.prevent
            >{{ $i18n('images') }}</a>
          </div>
        </li>
      </ul>
      <div class="sdds-footer-main-brand">
        <p
          v-if="showPriceDisclaimer && store.selectedPaymentType === 'leasing'"
          class="price-disclaimer"
        >
          {{ $i18n('priceDisclaimerText', euriborDate) }}
        </p>
        <!-- <p
          v-if="showPriceDisclaimer && store.selectedPaymentType === 'cash'"
          class="price-disclaimer"
        >
          {{ $i18n('priceDisclaimerCashText', euriborDate) }}
        </p> -->
        <p>{{ $i18n('copyrightText') }}</p>
      </div>
    </div>
    <InformationModal
      id="imagesModalId"
      :title="imageInformation.title"
      :information-text="imageInformation.text"
    />
    <InformationModal
      id="regulationsModal"
      :title="regulationsModal.title"
      :information-text="regulationsModal.text"
    />
  </footer>
</template>

<!--
======================================================================
 ██████╗███████╗███████╗    ███████╗████████╗██╗   ██╗██╗     ███████╗
██╔════╝██╔════╝██╔════╝    ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝
██║     ███████╗███████╗    ███████╗   ██║    ╚████╔╝ ██║     █████╗  
██║     ╚════██║╚════██║    ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  
╚██████╗███████║███████║    ███████║   ██║      ██║   ███████╗███████╗
 ╚═════╝╚══════╝╚══════╝    ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝
======================================================================
-->
<style scoped>
  .sdds-footer-main {
    background-color: var(--sdds-grey-900);
    color: var(--sdds-white);
  }
  .sdds-footer-main-links {
    text-transform: uppercase;
  }
  .sdds-footer-main .sdds-footer-main-brand {
    border-color: var(--sdds-white);
  }
  .sdds-footer-main .sdds-footer-main-brand > p:first-child {
    margin-bottom: var(--sdds-spacing-layout-16);
  }
  a {
    border-bottom: 0px;
  }
  .price-disclaimer {
    font-style: italic;
    margin-bottom: var(--sdds-spacing-layout-16);
  }
</style>

import { defineStore } from 'pinia';
import { getSpecification, getFpcCodeTranslations, getConfigurationV2, getMarketTranslations } from '../services/digitalSalesService';
import imagePlaceholder from '../assets/placeholder.png';
import { useConfiguratorApi } from '../composables/configurator';
import { v4 as uuidv4 } from 'uuid';

const { getImagePackage } = useConfiguratorApi();

export const useMainStore = defineStore('main', {
  state: () => ({
    initialDataLoaded: false,
    offerId: '',
    offerIds: {
      data: [],
      error: false,
    },
    offerStatuses: [],
    specification: [],
    fpcCodeTranslations: [],
    options: null,
    basePrice: null,
    repairMaintenancePrice: null,
    images: {
      view: imagePlaceholder,
      front: imagePlaceholder,
      side: imagePlaceholder,
      interior: imagePlaceholder,
      dashboard: imagePlaceholder,
    },
    selected: {},
    specification: null,
    token: null,
    locale: '',
    fallbackLocale: 'en-GB',
    translations: {},
    languages: [
      { locale: 'en-GB', description: 'English' },
      { locale: 'pl-PL', description: 'Polish' },
      { locale: 'de-DE', description: 'German' },
    ],
    sections: [
      { id: 'powertrain', name: 'drivelineHeaderTitle' },
      { id: 'cab-color', name: 'cabColourHeaderTitle' },
      { id: 'styling-comfort', name: 'stylingComfortHeaderTitle' },
      { id: 'chassis', name: 'chassisHeaderTitle' },
      { id: 'fifth-wheel', name: 'fifthWheelHeaderTitle' },
      { id: 'safety', name: 'safetyHeaderTitle' },
      { id: 'fuel-efficiency', name: 'fuelEfficiencyHeaderTitle' },
      { id: 'connectedServices', name: 'connectedServicesHeaderTitle' },
      // { id: 'driver-services', name: 'driverServicesHeaderTitle' },
      { id: 'repairMaintenance', name: 'repairMaintenanceHeaderTitle' },
    ],
    appLoading: false,
    supportRequested: false,
    isAdmin: false,
    isSuperUser: false,
    isSalesman: false,
    externalStaffReference: null,
    federationUserAccountId: null,
    misc: {},
    selectedPaymentType: 'leasing',
    selectedPaymentCurrency: 'EUR',
    currencies: [],
    selectedNumberVehicles: 1,
    tid: null,
    sectionMetadata: {},
    creationReferenceData: {},
    toasts: [],
    showYearEndOfferBanner: false,
    showYear2024OfferBanner: false,
    market: null,
    markets: [
      {
        marketId: 'pl',
        description: 'Poland',
        defaultLocale:'pl-PL',
        defaultHeroImageType: 'view',
        locales: ['pl-PL', 'en-GB'],
        linksMetadata: [
          { title: 'privacyPolicyText', link: 'https://www.scania.com/pl/pl/home/admin/misc/privacy-statement.html' },
          { title: 'cookiesText', link: 'https://www.scania.com/pl/pl/home/admin/misc/privacy-statement/cookies.html' },
          { title: 'environmentalPolicyText', link: 'https://www.scania.com/pl/pl/home/admin/misc/scania-environmental-policy.html' },
          { title: 'gdprText', link: 'https://www.scania.com/pl/pl/home/admin/misc/rodo.html' },
        ],
        highlightColor: 'var(--sdds-red-500)',
      },
      {
        marketId: 'de',
        description: 'Germany',
        defaultLocale:'de-DE',
        defaultHeroImageType: 'front',
        locales: ['de-DE', 'en-GB'],
        linksMetadata: [
          { title: 'privacyPolicyText', link: 'https://www.scania.com/de/de/home/admin/misc/privacy-statement.html' },
          { title: 'cookiesText', link: 'https://www.scania.com/de/de/home/admin/misc/privacy-statement/cookies.html' },
        ],
        highlightColor: 'var(--sdds-blue-400)',
      },
    ],
    featureToggleData: {},
  }),
  getters: {
    getSelected(state) {
      return state.selected;
    },
    getModifiedSpecification(state) {
      const specImpacts = this.getSpecImpactGrouped(state.selected).map(x => x.specImpact).flat(1);
      return state.specification?.map(item => ({
        ...item,
        selections: item.selections.map(subItem => {
          const impact = subItem.id === 'cabColour'
            ? specImpacts.find(x => x.id === subItem.id && !x.value.endsWith('-'))
            : specImpacts.find(x => x.id === subItem.id);
          return impact ? { id: impact.id, fpcCode: impact.value } : subItem;
        }),
      }));
    },
    getOfferStatus(state) {
      return state.offerStatuses.find(offer => offer.id === state.offerId);
    },
    activeOffers(state) {
      return state.offerStatuses.reduce((temp, item) => {
        if (!item.status.finalized && !item.status.expired && !item.status.isOfferNotValid) {
          temp.push({ offerId: item.id });
        };
        return temp;
      }, []);
    },
    offers(state) {
      return state.offerStatuses.reduce((temp, item) => {
        temp.push({ offerId: item.id });
        return temp;
      }, []);
    },
    getDefaultLocale(){
      const market = this.markets.filter(m=>m.marketId === this.market);
      return (market?.length) ? market[0].defaultLocale : this.fallbackLocale;
    },
    getLanguages(){
      const market = this.markets.filter(m=>m.marketId === this.market);
      const locales = (market?.length) ? market[0].locales : [this.fallbackLocale];
      return this.languages.filter(l => locales.includes(l.locale));;
    },
    footerLinkMetadataForActiveMarket(state) {
      // Return all link metadata for active market that do not have "footer" set to false
      return state.markets.find(market => market.marketId === state.market)?.linksMetadata?.filter(item => item.footer !== false) ?? [];
    },
    activeMarket(state) {
      // Return the active market configuration
      return state.markets.find(market => market.marketId === state.market) ?? null;
    },
  },
  actions: {
    setOfferIds(offerIds) {
      if(!offerIds) {
        this.offerIds.data = [];
        this.offerIds.error = true;
        return;
      }
      this.offerIds.data = offerIds;
      this.offerIds.error = false;
    },
    setOfferId(id) {
      this.offerId = id || this.offerStatuses.find(offer => !offer.status.expired && !offer.status.finalized && !offer.status.isOfferNotValid)?.id || this.offerIds.data[0];
    },
    getTranslation(key, locale = this.locale) {
      const marketKey =  this.market + String(key)?.charAt(0)?.toUpperCase() + String(key)?.slice(1);
      return this.translations[locale]?.[key] ||
        this.translations[locale]?.[marketKey] ||
        this.translations[this.fallbackLocale]?.[key] ||
        this.translations[this.fallbackLocale]?.[marketKey] ||
        key;
    },
    getSpecImpactGrouped(selections) {
      return Object.entries(selections)
      .map(([groupKey, group]) => {
        if (['leasing', 'payment', 'driverServices', 'connectedServices'].includes(groupKey)) return [];
        return Object.entries(group).map(([key, selection]) => ({ key, specImpact: selection.specImpact }));
      })
      .flat(1);
    },
    updateSelected(property, value) {
      this.selected[property] = value;
    },
    async loadTranslations() {
      if (this.translations[this.locale]) return;
      this.translations[this.locale] = await (await getMarketTranslations(this.market, this.locale)).data;
    },
    loadSpecification() {
      getSpecification()
      .then(response => {
        this.specification = response.data;
        this.loadSpecificationTranslations();
      });
    },
    loadSpecificationTranslations() {
      const fpcCodes = this.specification?.reduce((finalList, category) => {
        const categoryList = category.selections.reduce((categoryList, item) => [...categoryList, item.fpcCode], []);
        return [...finalList, ...categoryList];
      }, []);
      getFpcCodeTranslations(fpcCodes, this.locale)
      .then(response => {
        this.fpcCodeTranslations.push(...response.data);
      });
    },
    async loadConfiguration() {
      this.options = null;
      const response = await getConfigurationV2();
      this.options = response.data.options;
      this.basePrice = response.data.basePrice;
      this.repairMaintenancePrice = response.data.repairMaintenancePrice;
      const offerStatus = this.getOfferStatus;
      offerStatus.status.finalized = response.data.finalized;
      offerStatus.status.expired = response.data.expired;
      offerStatus.status.isOfferNotValid = response.data.isOfferNotValid || false;
      this.misc = response.data.misc;
      this.selectedPaymentType = response.data.options.payment?.find(o => o.id === 'paymentType')?.options?.find(o => o.default)?.value ?? 'leasing';
      this.selectedPaymentCurrency = response.data.options.payment?.find(o => o.id === 'paymentCurrency')?.options?.find(o => o.default)?.id ?? 'EUR';
      this.currencies = response.data.options.payment?.find(o => o.id === 'paymentCurrency')?.options ?? [];
      this.setConfigurationDefaults();
      this.loadConfigurationTranslations();
      this.sectionMetadata = response.data.sectionMetadata;
      this.creationReferenceData = response.data.creationReferenceData || {};
      return;
    },
    loadConfigurationTranslations() {
      const optionKeys = Object.keys(this.options || {});
      const fpcCodes = [];
      optionKeys.forEach(key => {
        this.options[key].forEach(item => {
          item.options.forEach(option => {
            const tempCodes = option.specImpact.flatMap(x =>
              (x.value && !x.value.endsWith('-'))
              ? [x.value]
              : [],
            );
            fpcCodes.push(...tempCodes);
          });
        });
      });
      const uniqueCodes = [...new Set(fpcCodes)];
      getFpcCodeTranslations(uniqueCodes, this.locale)
      .then(response => {
        this.fpcCodeTranslations.push(...response.data);
      });
    },
    setConfigurationDefaults() {
      const optionKeys = Object.keys(this.options);
      optionKeys.forEach(key => {
        this.options[key].forEach(item => {
          const defaultVal = item.options.find(x => x.default);
          if (!this.selected[key]) this.selected[key] = {};
          this.selected[key][item.id] = defaultVal || null;
        });
      });
      this.updateImages(this.selected);
    },
    updateImages(options) {
      getImagePackage(this.getSpecImpactGrouped(options))
      .then(response => {
        this.images = response;
      });
    },
    addToast(toast) {
      toast.id = uuidv4();
      this.toasts.push(toast);
    },
    addSuccessToast(toast) {
      if (typeof toast === 'string') {
        toast = {
          header: toast,
        };
      }
      this.addToast({
        ...toast,
        type: 'success',
      });
    },
    addErrorToast(toast) {
      if (typeof toast === 'string') {
        toast = {
          header: toast,
        };
      }
      this.addToast({
        ...toast,
        type: 'error',
      });
    },
    clearToast(id) {
      this.toasts = this.toasts.filter(x => x.id !== id);
    },
  },
});

import { addTrackingEvent, addTrackingEventByOffer } from '../services/digitalSalesService';
import { useMainStore } from '../store/index';

const addCustomTrackingEvent = async (eventName) => {
  try {
    const store = useMainStore();
    const eventData = {
      event: eventName,
      data: {
        timestamp: (new Date()).toISOString(),
      },
    };
    const isCustomer = !(store.isSuperUser || store.isAdmin || store.isSalesman);
    const isDevEnv = process.env.VUE_APP_ENV === 'dev';
    if (store.offerId && store.offerId !== '' && store.federationUserAccountId && (isCustomer || isDevEnv)) {
      // store event related the offer if is a real customer
      eventData.userId = store.federationUserAccountId;
      await addTrackingEventByOffer(eventData, store.offerId);
    } else if (store.tid) {
      // store events when is not related to the offer
      await addTrackingEvent(eventData, store.tid);
    }
  } catch (error) {
    console.error('failed to create tracking event', error);
  }
  return;
};

export {
  addCustomTrackingEvent,
};

import Keycloak from 'keycloak-js';

const keycloakUrl = process.env.VUE_APP_DS_KEYCLOAK_URL;

const kcOptions = {
  url: `${keycloakUrl}/auth`,
  realm: 'fg-ext',
  clientId: 'tf-ds-frontend',
};

const _keycloak = new Keycloak(kcOptions);

export default {
  install: (app, options) => {
    app.$keycloak = _keycloak;
    Object.defineProperties(app.config.globalProperties, {
      $keycloak: {
        get() {
          return _keycloak;
        },
      },
    });
    app.provide('keycloak', app.$keycloak);
  },
};

import { computed } from 'vue';
import { useMainStore } from '../store/index';
import { getImages } from '../services/digitalSalesService';

const getImagePackage = async (specificationImpacts) => {
  const response = await getImages(specificationImpacts);
  return Promise.resolve(response.data);
};

const getPrice = (option) => {
  return getRegularPrice(option.price) + getRepairMaintenancePrice(option.repairMaintenancePrice);
};

const getRegularPrice = (price) => {
  const store = useMainStore();
  if (typeof price === 'object' && !Array.isArray(price)) price = price[store.selectedPaymentCurrency][store.selectedPaymentType];
  if (!Array.isArray(price)) return price;
  return price
    .find(x => x.contractLength === store.selected.leasing.contractLength.id)
    .pricePerDownPayment
    .find(x => x.downPayment === store.selected.leasing.downPayment.id)
    .price;
};

const getRepairMaintenancePrice = (price) => {
  const store = useMainStore();
  if (typeof price === 'object' && !Array.isArray(price)) price = price[store.selectedPaymentCurrency];
  if (!Array.isArray(price)) return price;
  return price
    .find(x => x.contractLength === store.selected.leasing.contractLength.id)
    .pricePerMileage
    .find(x => x.mileage === store.selected.leasing.mileage.id)
    .price;
};

const getDownPaymentPrice = (price) => {
  const store = useMainStore();
  if (typeof price === 'object' && !Array.isArray(price)) price = price[store.selectedPaymentCurrency][store.selectedPaymentType];
  if (!Array.isArray(price)) return 0;
  const downPaymentPrice = price
    .find(x => x.contractLength === store.selected.leasing.contractLength.id)
    .pricePerDownPayment
    .find(x => x.downPayment === store.selected.leasing.downPayment.id)
    .downPaymentPrice;
  return (downPaymentPrice ? downPaymentPrice : 0);
};

const getTotalPrice = () => {
  const store = useMainStore();
  // Update defaultOptionsNotDisplayed when it has been split into service and product options separately
  const skipOptions = ['leasing', 'payment'];
  const serviceOptions = ['connectedServices', 'driverServices', 'repairMaintenance', 'defaultOptionsNotDisplayed'];
  const productOptions = Object.keys(store.selected).filter(x => ![...skipOptions, ...serviceOptions].includes(x));
  const filterObject = (object, allowedProperties) => {
    return Object.keys(object)
      .filter(key => allowedProperties.includes(key))
      .reduce((tempResult, key) => ({ ...tempResult, [key]: object[key] }), {});
  };

  const selectedProductOptionsPriceSum = Object.values(filterObject(store.selected, productOptions))
    .map(group => {
      return Object.values(group).map(selection => {
        if (store.selectedPaymentType === 'cash' && selection?.priceType === 'monthlyCost') return 0;
        return selection?.price ? getRegularPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);
  const selectedServiceOptionsPriceSum = Object.values(filterObject(store.selected, serviceOptions))
    .map(group => {
      return Object.values(group).map(selection => {
        if (store.selectedPaymentType === 'cash' && selection?.priceType === 'monthlyCost') return 0;
        return selection?.price ? getRegularPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);
  const selectedOptionsRepairMaintenancePriceSum = Object.values(store.selected)
    .map(group => {
      return Object.values(group).map(selection => {
        return selection?.repairMaintenancePrice ? getRepairMaintenancePrice(selection.repairMaintenancePrice) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);

  const selectedProductOptionsDownPaymentPrice = Object.values(filterObject(store.selected, productOptions))
    .map(group => {
      return Object.values(group).map(selection => {
        return selection?.price ? getDownPaymentPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);
  const selectedServiceOptionsDownPaymentPrice = Object.values(filterObject(store.selected, serviceOptions))
    .map(group => {
      return Object.values(group).map(selection => {
        return selection?.price ? getDownPaymentPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);

  const selectedMonthlyCostProductOptionsPriceSum = Object.values(filterObject(store.selected, productOptions))
    .map(group => {
      return Object.values(group).map(selection => {
        if (store.selectedPaymentType === 'cash' && selection?.priceType !== 'monthlyCost') return 0;
        return selection?.price ? getRegularPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);
  const selectedMonthlyCostServiceOptionsPriceSum = Object.values(filterObject(store.selected, serviceOptions))
    .map(group => {
      return Object.values(group).map(selection => {
        if (store.selectedPaymentType === 'cash' && selection?.priceType !== 'monthlyCost') return 0;
        return selection?.price ? getRegularPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);

  const basePrice = getRegularPrice(store.basePrice);
  const downPaymentBasePrice = getDownPaymentPrice(store.basePrice);
  const repairMaintenancePrice = (store.selected.repairMaintenance.repairMaintenancePackage.id === 'without') ?
    0 : getRepairMaintenancePrice(store.repairMaintenancePrice);
  const returnTotalPrice = {
    price: basePrice + selectedProductOptionsPriceSum + selectedServiceOptionsPriceSum + selectedOptionsRepairMaintenancePriceSum + repairMaintenancePrice,
    downPaymentPrice: downPaymentBasePrice + selectedProductOptionsDownPaymentPrice + selectedServiceOptionsDownPaymentPrice,
    priceBreakdown: {
      basePrice,
      selectedProductOptionsPrice: selectedProductOptionsPriceSum,
      selectedServiceOptionsPrice: selectedServiceOptionsPriceSum,
      repairMaintenancePrice,
      selectedOptionsRepairMaintenancePrice: selectedOptionsRepairMaintenancePriceSum,
    },
    priceSuffix: store.selected.payment.paymentCurrency.priceSuffix,
    priceSuffixOneTime: store.selected.payment.paymentCurrency.id,
    priceSuffixMonthly: store.selected.payment.paymentCurrency.priceSuffix,
  };
  if (store.selectedPaymentType === 'cash') {
    returnTotalPrice.price = basePrice + selectedProductOptionsPriceSum + selectedServiceOptionsPriceSum;
    returnTotalPrice.monthlyPrice = selectedMonthlyCostProductOptionsPriceSum + selectedMonthlyCostServiceOptionsPriceSum + selectedOptionsRepairMaintenancePriceSum + repairMaintenancePrice;
    returnTotalPrice.priceSuffix = store.selected.payment.paymentCurrency.id;
    returnTotalPrice.cashDepositPrice = store.misc?.cashDeposit ? Math.round(returnTotalPrice.price * store.misc.cashDeposit) : null;
  }
  //Total price Vehicles
  if((store.misc.allowedNumberOfVehicles ?? 1) > 1){
    returnTotalPrice.totalPriceMultVehicles = (returnTotalPrice.price * store.selectedNumberVehicles);
    if (returnTotalPrice.monthlyPrice) returnTotalPrice.monthlyPriceMultVehicles = (returnTotalPrice.monthlyPrice * store.selectedNumberVehicles);
    if (returnTotalPrice.cashDepositPrice) returnTotalPrice.cashDepositPriceMultVehicles = (returnTotalPrice.cashDepositPrice * store.selectedNumberVehicles);
  }
  return returnTotalPrice;
};

const buyBackValue = computed(() => {
  const store = useMainStore();
  if (store.selectedPaymentType === 'cash') return null;
  const buyBackValue = {
    'contractLength-1': '21%',
    'contractLength-2': '10%',
    'contractLength-3': '1%',
  };
  return buyBackValue[store.selected.leasing.contractLength.id];
});

const totalPrice = computed(() => {
  return getTotalPrice();
});

const totalFormattedPrice = computed(() => {
  const totalPrice = getTotalPrice();
  totalPrice.price = totalPrice.price.toLocaleString('en').replace(/,/g," ");
  if (totalPrice.monthlyPrice) totalPrice.monthlyPrice = totalPrice.monthlyPrice.toLocaleString('en').replace(/,/g," ");
  if (totalPrice.cashDepositPrice) totalPrice.cashDepositPrice = totalPrice.cashDepositPrice.toLocaleString('en').replace(/,/g," ");

  if (totalPrice.totalPriceMultVehicles) totalPrice.totalPriceMultVehicles = totalPrice.totalPriceMultVehicles.toLocaleString('en').replace(/,/g," ");
  if (totalPrice.monthlyPriceMultVehicles) totalPrice.monthlyPriceMultVehicles = totalPrice.monthlyPriceMultVehicles.toLocaleString('en').replace(/,/g," ");
  if (totalPrice.cashDepositPriceMultVehicles) totalPrice.cashDepositPriceMultVehicles = totalPrice.cashDepositPriceMultVehicles.toLocaleString('en').replace(/,/g," ");  
  return totalPrice;
});

const repairMaintenanceBasePrice = computed(() => {
  const store = useMainStore();
  return getRepairMaintenancePrice(store.repairMaintenancePrice);
});

const repairMaintenanceBasePriceOptional = computed(() => {
  const store = useMainStore();
  return (store.selectedPaymentType === 'cash') && (store.selected.repairMaintenance.repairMaintenancePackage.id === 'without') ?
  0 : getRepairMaintenancePrice(store.repairMaintenancePrice) + getRepairMaintenancePrice(store.selected.repairMaintenance.repairMaintenanceTachograph?.repairMaintenancePrice || 0);
});

const servicesPrice = computed(() => {
  const store = useMainStore();
  const serviceOptions = ['connectedServices', 'driverServices'];
  const filterObject = (object, allowedProperty) => {
    return Object.keys(object)
      .filter(key => key === allowedProperty)
      .reduce((tempResult, key) => ({ ...tempResult, [key]: object[key] }), {});
  };
  const selectedMonthlyCostServiceOptionsPrice = (serviceOption) => Object.values(filterObject(store.selected, serviceOption))
    .map(group => {
      return Object.values(group).map(selection => {
        if (store.selectedPaymentType === 'cash' && selection?.priceType !== 'monthlyCost') return 0;
        return selection?.price ? getRegularPrice(selection.price) : 0;
      })
      .reduce((prev, next) => prev + next, 0);
    })
    .reduce((prev, next) => prev + next, 0);
  return Object.fromEntries(serviceOptions.map(key => [key, selectedMonthlyCostServiceOptionsPrice(key)]));
});

const useConfiguratorApi = () => {
  return {
    totalPrice,
    totalFormattedPrice,
    repairMaintenanceBasePrice,
    repairMaintenanceBasePriceOptional,
    buyBackValue,
    servicesPrice,
    getPrice,
    getRegularPrice,
    getRepairMaintenancePrice,
    getImagePackage,
  };
};

export {
  useConfiguratorApi,
};

<script setup>
  /* eslint-disable vue/no-deprecated-slot-attribute */ // Slot attribute used by SDDS
import { computed } from 'vue';
import MarkupSegment from './MarkupSegment';
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  informationText: {
    type: String,
    default: '',
  },
  informationImage: {
    type: String,
    default: '',
  },
});

const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

const images = computed(() => {
  return props.informationImage.split(',');
});
</script>
<template>
  <sdds-modal
    size="md"
    :selector="`#${props.id}`"
    class="modal"
  >
    <h3
      v-if="props.title"
      slot="sdds-modal-headline"
    >
      {{ $i18n(props.title) }}
    </h3>
    <div
      slot="sdds-modal-body"
      class="modal__content"
    >
      <MarkupSegment
        v-if="props.informationText"
        class="modal__content-information"
        :text="$i18n(props.informationText)"
      />
      <div
        v-if="props.informationImage"
        class="modal__content-image"
      >
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="`${staticImagesBaseUrl}/${image}`"
          alt="Information image"
        >
      </div>
    </div>
    <button
      slot="sdds-modal-actions"
      data-dismiss-modal
      class="sdds-btn sdds-btn-primary sdds-btn-md"
      data-dd-action-name="close"
    >
      {{ $i18n('closeButtonText') }}
    </button>
  </sdds-modal>
</template>

<style scoped>
  .modal__content {
    display: flex;
  }
    .modal__content-information {
      flex: 1 0.5;
    }
    .modal__content-image {
      display: flex;
      flex: 0 0.5 33%;
      margin-top: 2em;
      margin-left: 2em;
      align-items: flex-start;
    }
      .modal__content-image img {
        object-fit:contain;
        max-width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -ms-transform: translateZ(0);
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        transform: translateZ(0);
      }

  @media only screen and (max-width: 1024px) {
    .modal__content {
       display: inline;
    }
    .modal {
      overflow-y: auto;
    }
  }

</style>

<script setup>
import { computed } from 'vue';
import ConfigurationCheckSelection from '../ConfigurationCheckSelection';
import ConfigurationRadioSelection from '../ConfigurationRadioSelection';
import ConfigurationPackageSelectionRequired from '../ConfigurationPackageSelectionRequired';
import SelectOptionInfo from '../SelectOptionInfo';
import { useMainStore } from '../../store/index.js';

import '../../styles/configurator-section.css';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
});

const safetyPackage = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'safetyPackage');
});
const fuelTheftAlarm = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'fuelTheftAlarm');
});
const tyrePressureMonitor = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'tyrePressureMonitor');
});

const otherItems = computed(() => {
  if (!props.items) return null;
  return props.items.filter(x => !['safetyPackage', 'fuelTheftAlarm', 'tyrePressureMonitor'].includes(x.id));
});

const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});
</script>

<template>
  <section
    v-if="props.items"
    id="safety"
    class="configurator-section"
  >
    <h2>{{ $i18n('safetyHeaderTitle') }}</h2>
    <template v-if="specType !== 'stock_vehicle'">
      <div class="configurator-section__row">
        <ConfigurationCheckSelection
          v-if="safetyPackage"
          v-model="store.selected.safety[safetyPackage.id]"
          :type="safetyPackage.id"
          :options="safetyPackage.options"
          @select="emit('select', $event)"
        />
        <div 
          v-if="safetyPackage && fuelTheftAlarm" 
          class="sdds-divider-dark-vertical"
        />
        <ConfigurationCheckSelection
          v-if="fuelTheftAlarm"
          v-model="store.selected.safety[fuelTheftAlarm.id]"
          :type="fuelTheftAlarm.id"
          :options="fuelTheftAlarm.options"
          @select="emit('select', $event)"
        />
        <div 
          v-if="(safetyPackage && tyrePressureMonitor) || (fuelTheftAlarm && tyrePressureMonitor)" 
          class="sdds-divider-dark-vertical"
        />
        <ConfigurationCheckSelection
          v-if="safetyPackage && tyrePressureMonitor"
          v-model="store.selected.safety[tyrePressureMonitor.id]"
          :type="tyrePressureMonitor.id"
          :options="tyrePressureMonitor.options"
          @select="emit('select', $event)"
        />
      </div>
      <template
        v-for="item in otherItems"
        :key="item.id"
      >
        <ConfigurationCheckSelection
          v-if="item.selectionType === 'checkbox'"
          v-model="store.selected.safety[item.id]"
          :type="item.id"
          :options="item.options"
          @select="emit('select', $event)"
        />
        <ConfigurationRadioSelection
          v-if="item.selectionType === 'radio'"
          v-model="store.selected.safety[item.id]"
          :type="item.id"
          :title="item.title"
          :options="item.options"
          :justify-content-center="specType === 'stock_vehicle'"
          @select="emit('select', $event)"
        />
        <ConfigurationPackageSelectionRequired
          v-if="item.selectionType === 'packageRequired'"
          v-model="store.selected.safety[item.id]"
          :item="item"
          @select="emit('select', $event)"
        />
      </template>
    </template>
    <template v-if="specType === 'stock_vehicle'">
      <div class="configurator-section__row">
        <div
          v-if="safetyPackage"
          class="configurator-selection"
        >
          <div class="configurator-selection__checkoption">
            <SelectOptionInfo
              :checkbox="true"
              :option="safetyPackage.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
        <div 
          v-if="safetyPackage && fuelTheftAlarm" 
          class="sdds-divider-dark-vertical"
        />
        <div
          v-if="fuelTheftAlarm"
          class="configurator-selection"
        >
          <div class="configurator-selection__checkoption">
            <SelectOptionInfo
              :checkbox="true"
              :option="fuelTheftAlarm.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
        <div 
          v-if="(safetyPackage && tyrePressureMonitor) || (fuelTheftAlarm && tyrePressureMonitor)" 
          class="sdds-divider-dark-vertical"
        />
        <div
          v-if="safetyPackage && tyrePressureMonitor"
          class="configurator-selection"
        >
          <div class="configurator-selection__checkoption">
            <SelectOptionInfo
              :checkbox="true"
              :option="tyrePressureMonitor.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
      </div>
      <template
        v-for="item in otherItems"
        :key="item.id"
      >
        <div
          v-if="item.selectionType === 'checkbox'"
          class="configurator-selection"
        >
          <div class="configurator-selection__checkoption">
            <SelectOptionInfo
              :checkbox="true"
              :option="item.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
        <div
          v-if="item.selectionType === 'radio'"
          class="configurator-selection"
        >
          <h3
            v-if="item.title"
            class="configurator-selection__title"
          >
            {{ $i18n(item.title) }}
          </h3>
          <div
            class="configurator-selection__options"
            :class="{ 'justify-content-center': true }"
          >
            <SelectOptionInfo
              :option="item.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
        <ConfigurationPackageSelectionRequired
          v-if="item.selectionType === 'packageRequired'"
          v-model="store.selected.safety[item.id]"
          :item="item"
          @select="emit('select', $event)"
        />
      </template>
    </template>
  </section>
</template>
<style scoped>
  .configurator-selection__checkoption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 30em;
    height: 100%;
    width: 100%;
  }

  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);   
    align-self: center;
  }

  @media only screen and (max-width: 1000px) {
    .sdds-divider-dark-vertical {
      display: none;
    }
    .configurator-section__row {
      flex-direction: column;
    }
  }
</style>

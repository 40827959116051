<script setup>
import InformationIcon from './InformationIcon';
import { useConfiguratorApi } from '../composables/configurator';

const { totalFormattedPrice } = useConfiguratorApi();

const props = defineProps({
  selectorId: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: 'top',
  },
});

</script>

<template>
  <sdds-tooltip
    :placement="props.placement"
    :selector="`#${selectorId}`"
    mouse-over-tooltip="true"
  >
    <ul>
      <li>
        <strong>{{ $i18n('downPaymentSubheaderTitle') }}:</strong> {{ totalFormattedPrice.cashDepositPrice }}&nbsp;
        <span
          v-if="totalFormattedPrice.cashDepositPrice"
          class="sdds-detail-05"
        >{{ $i18n(totalFormattedPrice.priceSuffix) }}</span>
      </li>
    </ul>
  </sdds-tooltip>
  <InformationIcon :id="selectorId" />
</template>

<style scoped>
  sdds-tooltip > ul {
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    white-space: pre-wrap;
  }
</style>

<template>
  <div class="spinner fa-6x">
    <font-awesome-icon
      icon="spinner"
      class="fa-spin"
    />
  </div>
</template>

<style>
  .spinner {
    display: grid;
    height: calc(100vh - 72px);
    place-items: center;
  }
</style>

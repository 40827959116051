<script setup>
import { ref, computed } from 'vue';
const props = defineProps({
  disabled: Boolean,
  header: {
    type: String,
    default: '',
  },
  headerHelperText: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Number,
    default: () => {
      return 0;
    },
  },
  max: {
    type: Number,
    default: Infinity,
  },
  min: {
    type: Number,
    default: -Infinity,
  },
  step: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(['update:modelValue']);

const currentValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});
const decrementDisabled = ref(false);
const incrementDisabled = ref(false);

const checkValue = (newVal) => {
  const oldVal = currentValue.value;
  if (oldVal === newVal) {
    return;
  }
  if (newVal <= props.min) {
    newVal = props.min;
    decrementDisabled.value = true;
  }
  if (newVal >= props.max) {
    newVal = props.max;
    incrementDisabled.value = true;
  }
  currentValue.value = newVal;
};
const decrement = () => {
  if (props.disabled || decrementDisabled.value) {
    return;
  }
  const newVal = currentValue.value - (props.step * 1);
  incrementDisabled.value = false;
  checkValue(newVal);
};

const increment = () => {
  if (props.disabled || incrementDisabled.value) {
    return;
  }
  const newVal = currentValue.value + (props.step * 1);
  decrementDisabled.value = false;
  checkValue(newVal);
};

const checkValueInput = () => {
  let newVal = currentValue.value;
  if (newVal <= props.min) {
    newVal = props.min;
    decrementDisabled.value = true;
    incrementDisabled.value = false;
  }
  if (newVal >= props.max) {
    newVal = props.max;
    incrementDisabled.value = true;
    decrementDisabled.value = false;
  }
  currentValue.value = newVal;
};

</script>
<template>
  <div class="general-container">
    <div class="flex-container">
      <h5 class="flex-grow sdds-u-mt1">
        {{ $i18n(header) }}
        <template v-if="headerHelperText !== ''">
          ({{ $i18n(headerHelperText) }})
        </template>
      </h5>
    </div>
    <div class="flex-container">
      <button
        class="sdds-btn sdds-btn-secondary btn-number sdds-u-mr1"
        :disabled="decrementDisabled"
        @click="decrement"
      >
        −
      </button>
      <input
        v-model="currentValue"
        type="number"
        class="sdds-textfield-input2 textfield-input"
        :disabled="props.disabled"
        :min="min"
        :max="max"
        :step="step"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        @keyup="checkValueInput"
        @keydown.esc="checkValue(value)"
        @keydown.enter="checkValue(value)"
        @keydown.up.prevent="increment"
        @keydown.down.prevent="decrement"
      >
      <button
        class="sdds-btn sdds-btn-secondary btn-number sdds-u-ml1"
        :disabled="incrementDisabled"
        @click="increment"
      >
        +
      </button>
    </div>
  </div>
</template>
<style scoped>
h5 {
  margin-bottom: var(--sdds-spacing-element-0);
  font-weight: 500;
}

.general-container {
  padding-bottom: var(--sdds-spacing-element-12)
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container>div {
  margin-right: var(--sdds-spacing-element-24);
}

.flex-container>div:nth-child(2) {
  margin-right: var(--sdds-spacing-element-48);
}

.flex-grow {
  flex-grow: 1;
}

.textfield-input {
  border: none;
  height: 100%;
  color: var(--sdds-textfield-color);
  background-color: var(--sdds-grey-50);
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  font-size: calc((14/ 4) * 1rem);
  line-height: calc((16/ 4) * 1rem);
  letter-spacing: -0.01em;
  padding: var(--sdds-spacing-element-8) var(--sdds-spacing-element-8);
  font-weight: 600;
}

.btn-number {
  height: 100%;
  padding-top: var(--sdds-spacing-element-8);
  padding-bottom: var(--sdds-spacing-element-8);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

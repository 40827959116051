<script setup>
import { computed } from 'vue';
import ConfigurationPackageSelectionRequired from '../ConfigurationPackageSelectionRequired';
import ConfigurationPackageSelectionOptional from '../ConfigurationPackageSelectionOptional';
import ConfigurationPackageInformation from '../ConfigurationPackageInformation';
import ServiceContractSelection from '../ServiceContractSelection';
import { useMainStore } from '../../store/index.js';

import '../../styles/configurator-section.css';
import ConfigurationCheckSelection from '../ConfigurationCheckSelection.vue';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  skipEcolutionValidation: {
    type: Boolean,
    default: false,
  },
});

const control = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'fleetManagementControl');
});

const dataAccess = computed(() => {
  if (!props.items) return null;
  return props.items.filter(x => x.id.includes('fleetManagementDataAccess'));
});

const eToll = computed(() => {
  if (!props.items) return null;
  const eTollObj = props.items.filter(x => x.id.includes('fleetManagementE-Toll'));
  if (eTollObj.length) return eTollObj;
  return null;
});

const tachograph = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'tachographServices');
});

const driverTraining = computed(() => {
  return store.options.driverServices.find(x => x.id === 'driverTraining');
});

const driverCoaching = computed(() => {
  return store.options.driverServices.find(x => x.id === 'driverCoaching');
});

const coachingDisabled = computed(() => {
  if (!driverTraining.value) return true;
  return store.selected.driverServices[driverTraining.value.id].id === 'without';
});

const disabledSelections = computed(() => {
  if (!driverCoaching.value) return  [];
  else if (store.selected.driverServices.driverCoaching.id === 'driverCoaching') return ['without'];
  return [];
});

const showInfo = computed(() => {
  if (!driverCoaching.value) return  false;
  return store.selected.driverServices.driverCoaching.id === 'driverCoaching';
});

const handleDriverTrainingSelect = (event) => {
  emit('select', event);
  if(event.option.id === 'without' && driverCoaching.value) {
    store.selected.driverServices[driverCoaching.value.id] = driverCoaching.value.options.find(x => x.id === 'without');
  }
};

</script>

<template>
  <section
    v-if="props.items"
    id="connectedServices"
    class="configurator-section"
  >
    <h2>{{ $i18n('connectedServicesHeaderTitle') }}</h2>
    <ServiceContractSelection v-if="store.selectedPaymentType === 'cash'" />
    <div class="driver-coaching-ecolution">
      <div
        v-if="!props.skipEcolutionValidation && coachingDisabled"
        class="driver-coaching-banner sdds-banner sdds-banner-info el-margin-bottom-8"
      >
        <div class="sdds-banner-body">
          <div class="sdds-banner-subheader">
            {{ $i18n('driverTrainingNeedToBeSelected') }}
          </div>
        </div>
      </div>
      <ConfigurationPackageSelectionOptional
        v-if="driverCoaching"
        v-model="store.selected.driverServices[driverCoaching.id]"
        :type="driverCoaching.id"
        :options="driverCoaching.options"
        :disabled="coachingDisabled"
        :offer-text="driverCoaching.offerText || null"
        @select="emit('select', $event)"
      />
    </div>
    <ConfigurationPackageSelectionRequired
      v-if="driverTraining"
      v-model="store.selected.driverServices[driverTraining.id]"
      :item="driverTraining"
      :disabled-selections="disabledSelections"
      :show-info="showInfo"
      :info-message="$i18n('noDriverTrainingCanNotBeSelected')"
      @select="handleDriverTrainingSelect"
    />
    <ConfigurationPackageSelectionRequired
      v-if="control && control.options.length > 1"
      v-model="store.selected.connectedServices[control.id]"
      :item="control"
      :offer-text="control.offerText || null"
      @select="emit('select', $event)"
    />
    <ConfigurationPackageInformation
      v-if="control && control.options.length === 1"
      :item="control"
      :offer-text="control.offerText || null"
      @loadeddata="emit('select', { type: control.id, option: store.selected.connectedServices[control.id] })"
    />
    <div
      v-if="dataAccess"
      class="configurator-selection"
    >
      <h3>{{ $i18n(dataAccess[0].title) }}</h3>
      <div class="configurator-section__row">
        <ConfigurationCheckSelection
          v-for="item in dataAccess"
          :key="item.id"
          v-model="store.selected.connectedServices[item.id]"
          :type="item.id"
          :options="item.options"
          @select="emit('select', $event)"
        />
      </div>
    </div>
    <div
      v-if="eToll"
      class="configurator-selection"
    >
      <h3>{{ $i18n(eToll[0].title) }}</h3>
      <div class="configurator-section__row">
        <ConfigurationCheckSelection
          v-for="item in eToll"
          :key="item.id"
          v-model="store.selected.connectedServices[item.id]"
          :type="item.id"
          :options="item.options"
          @select="emit('select', $event)"
        />
      </div>
    </div>
    <ConfigurationPackageSelectionRequired
      v-if="tachograph"
      v-model="store.selected.connectedServices[tachograph.id]"
      :item="tachograph"
      @select="emit('select', $event)"
    />
  </section>
</template>

<style scoped>
  @media only screen and (max-width: 1294px) {
    .configurator-section__row {
      flex-direction: column;
    }
  }
  .driver-coaching-ecolution {
    max-width: 70em;
  }
  @media only screen and (max-width: 1294px) {
    .driver-coaching-ecolution {
      max-width: 90vw;
    }
  }
</style>

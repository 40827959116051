<script setup>
import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '../store';
import RegulationsApproval from '../components/RegulationsApproval';
import ContactDetails from '../components/ContactDetails';
import { removeUserResetPasswordMapping } from '../services/digitalSalesService';
import { addCustomTrackingEvent } from '../utils/customTracking';

const router = useRouter();
const keycloak = inject('keycloak');
const adobeEvent = inject('adobeEvent');
const store = useMainStore();

const requiredRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_ROLE;
const requiredSalesmanRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_SALESMAN_ROLE;

const hasRole = keycloak.hasResourceRole(requiredRole) || keycloak.hasResourceRole(requiredSalesmanRole);
const isAuthorized = keycloak.authenticated && hasRole;
const noOffersAvailable = ref(false);
const errorLoadingOffers = ref(false);
const notAllowedToSeeOffer = ref(false);
const adminDsUserMissing = ref(false);
const offerExpired = ref(false);
const isOfferNotValid = ref(false);
const isError = ref(false);

onMounted(() => {
  isError.value = checkUser() || false;
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'HomeView',
      offerId: store.offerId,
      regulationsApproved: store.getOfferStatus?.status.regulationsApprovedForUser,
      error: {
        isErrorPage: isError.value,
        data: {
          adminDsUserMissing: adminDsUserMissing.value,
          hasRole,
          isAuthorized,
          noOffersAvailable: noOffersAvailable.value,
          notAllowedToSeeOffer: notAllowedToSeeOffer.value,
          errorLoadingOffers: errorLoadingOffers.value,
          offerExpired: offerExpired.value,
          ...(isOfferNotValid.value ? { isOfferNotValid: true } : {}),
        },
      },
    },
  });
  addCustomTrackingEvent('homeView');
});
const checkUser = () => {
  if(!hasRole && store.isAdmin) {
    adminDsUserMissing.value = true;
    return true;
  }

  if(!isAuthorized) return true;

  if(store.offerIds.error) {
    errorLoadingOffers.value = true;
    return true;
  }
  if(!store.offerId && store.offerIds.data.length < 1) {
    noOffersAvailable.value = true;
    return true;
  }
  if(store.offerId && !store.offerIds.data.includes(store.offerId)) {
    notAllowedToSeeOffer.value = true;
    return true;
  }
  if(store.offerId && store.getOfferStatus?.status.expired) {
    offerExpired.value = true;
    return true;
  }
  if(store.offerId && store.getOfferStatus?.status.isOfferNotValid) {
    isOfferNotValid.value = true;
    return true;
  }
  if(!store.getOfferStatus?.status.regulationsApprovedForUser) {
    removeUserResetPasswordMapping(store.federationUserAccountId).catch(err => {
      console.error('failed to remove user from reset password mapping: ', err);
    });
    return;
  }

  router.push({ name: 'ConfiguratorView', params: { market: store.market }, query: { id: store.offerId }});
};

const proceedToConfigurator = () => {
  store.appLoading = true;
  store.loadConfiguration()
    .then(() => {
      store.appLoading = false;
      router.push({ name: 'ConfiguratorView', params: { market: store.market }, query: { id: store.offerId}});
    });
};


</script>
<template>
  <section>
    <template v-if="adminDsUserMissing">
      <h1>Missing role</h1>
      <p>ds-user is missing</p>
    </template>
    <template v-else>
      <template v-if="!isAuthorized">
        <h1>Road closed</h1>
        <p>You do not have access to this page</p>
        <a
          href="https://my.scania.com"
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="go to MyScania"
        >
          Go to MyScania
        </a>
      </template>
      <template v-if="!hasRole || notAllowedToSeeOffer">
        <h1>{{ $i18n('roadClosed') }}</h1>
        <p>{{ $i18n('restrictedAccess') }}</p>
        <a
          href="https://my.scania.com"
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="go to MyScania"
        >
          {{ $i18n('goToMyScania') }}
        </a>
      </template>
      <template v-if="noOffersAvailable">
        <h1>{{ $i18n('noActiveOffers') }}</h1>
        <p>{{ $i18n('noActiveOffersText') }}</p>
        <a
          href="https://my.scania.com"
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="go to MyScania"
        >
          {{ $i18n('goToMyScania') }}
        </a>
      </template>
      <template v-if="offerExpired">
        <h1>{{ $i18n('offerExpired') }}</h1>
        <p>{{ $i18n('offerExpiredText') }}</p>
        <ContactDetails />
      </template>
      <template v-if="isOfferNotValid">
        <h1>{{ $i18n('offerNotValid') }}</h1>
        <p>{{ $i18n('offerNotValidText') }}</p>
        <ContactDetails />
      </template>
      <template v-if="errorLoadingOffers">
        <h1>{{ $i18n('error') }}</h1>
        <p>{{ $i18n('errorLoadingOffer') }}</p>
        <a
          href="https://buy.scania.com"
          class="sdds-btn sdds-btn-primary"
          data-dd-action-name="try again"
        >
          {{ $i18n('tryAgain') }}
        </a>
      </template>
      <template v-if="!noOffersAvailable && !store.getOfferStatus?.status.regulationsApprovedForUser && !isError">
        <RegulationsApproval
          @approved="store.getOfferStatus.status.regulationsApprovedForUser = true; proceedToConfigurator();"
        />
      </template>
    </template>
  </section>
</template>

<style scoped>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
  @media only screen and (max-width: 500px) {
    section {
      padding: 1em;
    }
  }
</style>

<script setup>
import { computed } from 'vue';
import { useMainStore } from '../store/index.js';

const store = useMainStore();

const props = defineProps({
  item: {
    type: Object,
    default() {
      return { id: '', data: null };
    },
  },
});

const itemId = computed(() => {
  return props.item.id;
});

const itemIdTranslationKey = computed(() => {
  return `${itemId.value}HeaderTitle`;
});

const getTitle = (key) => {
  return store.options[itemId.value]?.find(x => x.id === key)?.title;
};
</script>

<template>
  <div
    v-if="itemId"
  >
    <div>
      <h5>
        {{ $i18n(itemIdTranslationKey) }}
      </h5>
    </div>
    <div
      v-for="(subItem, key) in props.item.data"
      :key="key"
      class="summary-selections"
    >
      <div><strong>{{ $i18n(getTitle(key)) }}</strong></div> <div>{{ $i18n(subItem.value) }}</div>
    </div>
  </div>
</template>

<style scoped>
  h5 {
    border-bottom: 1px solid var(--sdds-grey-200);
    margin-bottom: var(--sdds-spacing-element-8);
  }
  .summary-selections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    padding-bottom: var(--sdds-spacing-element-4);
    width: 100%;
  }
</style>


<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import ConfigurationRadioSelection from '../../ConfigurationRadioSelection';
import SelectOptionInfo from '../../SelectOptionInfo';
import ConfigurationCheckSelection from '../../ConfigurationCheckSelection';
import { useMainStore } from '../../../store/index.js';

import '../../../styles/configurator-section.css';
import '../../../styles/configurator-selection.css';

const windowWidth = ref(window.innerWidth);

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    required: true,
  },
  isSpecEB: {
    type: Boolean,
    required: true,
  },
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const suspensionDsv = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'suspensionDsv');
});

const groundClearance = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'groundClearance');
});

const fuelTanks = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'fuelTanks');
});

const tyreSupplier = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'tyreSupplier');
});
const spareWheel = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'spareWheel');
});
const tyreSize = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'tyreSize');
});
const customConfiguratorSelectionOptionsStyleObject = computed(() => ({
  ...windowWidth.value > 650 && { justifyContent: 'center' },
}));
</script>

<template>
  <section
    v-if="props.items"
    id="chassis"
    class="configurator-section"
  >
    <h2>{{ $i18n('chassisHeaderTitle') }}</h2>
    <div
      v-if="fuelTanks && props.images.isSide"
      class="configurator-section__image is-side-image-custom-margin"
    >
      <img
        :src="props.images.isSide"
        alt="Truck - side view"
      >
    </div>
    <ConfigurationRadioSelection
      v-if="suspensionDsv"
      v-model="store.selected.chassis[suspensionDsv.id]"
      :type="suspensionDsv.id"
      :options="suspensionDsv.options"
      :title="suspensionDsv.title"
      @select="emit('select', $event)"
    />
    <div
      v-if="fuelTanks"
      class="configurator-selection"
    >
      <h3
        v-if="fuelTanks.title"
        class="configurator-selection__title"
      >
        {{ $i18n(fuelTanks.title) }}
      </h3>
      <div
        class="configurator-selection__options"
        :class="{ 'justify-content-center': true }"
      >
        <SelectOptionInfo
          :option="fuelTanks.options[0]"
          :selected="true"
          :show-gap-above-description="true"
        />
      </div>
    </div>
    <h3
      v-if="props.images.side && props.images.isSide"
    >
      {{ $i18n('dimensionsTitle') }}
    </h3>
    <div
      v-if="props.images.side"
      class="configurator-section__image side-image-custom-margin"
    >
      <img
        :src="props.images.side"
        alt="Truck - side view"
      >
    </div>
    <div
      v-if="groundClearance"
      class="configurator-selection"
    >
      <h3
        v-if="groundClearance.title"
        class="configurator-selection__title"
      >
        {{ $i18n(groundClearance.title) }}
      </h3>
      <div
        class="configurator-selection__options"
        :class="{ 'justify-content-center': true }"
      >
        <SelectOptionInfo
          :option="groundClearance.options[0]"
          :selected="true"
          :show-gap-above-description="true"
        />
      </div>
    </div>
    <template v-if="tyreSize">
      <div class="configurator-selection">
        <h3
          v-if="tyreSize.title"
          class="configurator-selection__title sdds-u-mb0"
        >
          {{ $i18n(tyreSize.title) }}
        </h3>
        <div
          class="configurator-selection__options justify-content-space-between"
          :style="customConfiguratorSelectionOptionsStyleObject"
        >
          <div
            v-for="option in tyreSize.options"
            :key="option.id"
            style="flex: 0 1 25em;"
          >
            <div
              v-if="option['id']"
              class="sdds-u-textalign-center"
            >
              <h4 class="sdds-u-mb3">
                {{ $i18n(option['id']) }}
              </h4>
            </div>
            <SelectOptionInfo
              :option="option"
              :selected="true"
              custom-description-image-flex="0 0 6em"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="configurator-section__row">
      <ConfigurationRadioSelection
        v-if="tyreSupplier"
        v-model="store.selected.chassis[tyreSupplier.id]"
        :type="tyreSupplier.id"
        :options="tyreSupplier.options"
        :title="tyreSupplier.title"
        @select="emit('select', $event)"
      />
      <template v-if="spareWheel">
        <div class="sdds-divider-dark-vertical" />
        <ConfigurationCheckSelection
          v-model="store.selected.chassis[spareWheel.id]"
          :type="spareWheel.id"
          :title="spareWheel.title"
          :options="spareWheel.options"
          @select="emit('select', $event)"
        />
      </template>
    </div>
  </section>
</template>

<style scoped>
.sdds-divider-dark-vertical {
  height: var(--sdds-spacing-layout-72);
  margin-top: var(--sdds-spacing-element-48);
  align-self: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.configurator-section__image {
  max-width: 70%;
}

.configurator-section__image>img {
  max-width: 100%;
  object-fit: cover;
}

.is-side-image-custom-margin,
.side-image-custom-margin {
  margin-top: -12rem;
}

@media only screen and (max-width: 1294px) {
  .configurator-section__image>img {
    height: initial;
  }

  .is-side-image-custom-margin,
  .side-image-custom-margin {
    margin-top: -10rem;
  }
}

@media only screen and (max-width: 1000px) {
  .configurator-section__image {
    width: 90%;
  }

  .sdds-divider-dark-vertical {
    display: none;
  }

  .configurator-section__row {
    flex-direction: column;
  }
}
</style>

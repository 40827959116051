<script setup>
import { computed } from 'vue';
import SelectPackageOption from './SelectPackageOption';

import '../styles/configurator-selection.css';

const emit = defineEmits(['select', 'update:modelValue']);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object,
    default: () => {
      return null;
    },
  },
  options: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  packageLayout: {
    type: Boolean,
    default: false,
  },
  offerText: {
    type: String,
    default: null,
  },
});

const checkboxOption = computed(() => {
  return props.options.find(x => !isWithoutOption(x));
});

const withoutOption = computed(() => {
  return props.options.find(x => isWithoutOption(x));
});

const select = () => {
  const emitValue = isWithoutOption(props.modelValue) ? checkboxOption.value : withoutOption.value;
  emit('select', { option: emitValue, type: props.type });
  emit('update:modelValue', emitValue);
};

const isWithoutOption = (option) => {
  return ['without'].includes(option.id);
};

</script>

<template>
  <div class="configurator-selection">
    <h3
      v-if="props.title"
      class="configurator-selection__title"
    >
      {{ $i18n(title) }}
    </h3>
    <div class="configurator-selection__checkoption check-option-full-width">
      <SelectPackageOption
        :option="checkboxOption"
        :checkbox="true"
        :disabled="props.disabled"
        :selected="!isWithoutOption(modelValue)"
        :package-layout="packageLayout"
        :offer-text="props.offerText"
        @click="select()"
      />
    </div>
  </div>
</template>

<style scoped>
  .configurator-selection__checkoption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 30em;
  }
  .configurator-selection__checkoption.check-option-full-width {
    max-width: 100%;
  }
</style>

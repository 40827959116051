export default {
  install: (app, store) => {
    const pushEvent = (event) => {
      event = {
        ...event,
        site: 'digital sales',
        locale: store.locale,
        isAdmin: store.isAdmin,
        isSuperUser: store.isSuperUser,
        isSalesman: store.isSalesman,
        externalStaffReference: store.externalStaffReference,
        federationUserAccountId: store.federationUserAccountId,
        siteSection: 'private',
      };
      window.adobeDataLayer.push(event);
    };

    app.config.globalProperties.$adobeEvent = pushEvent;
    app.provide('adobeEvent', pushEvent );
  },
};

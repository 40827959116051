<script setup>
/* eslint-disable vue/no-v-html */
import { computed, ref, onMounted, onUnmounted } from 'vue';
import ConfigurationRadioSelection from '../../ConfigurationRadioSelection';
import ConfigurationCheckSelection from '../../ConfigurationCheckSelection';
import { useMainStore } from '../../../store/index.js';

import '../../../styles/configurator-section.css';
import '../../../styles/configurator-selection.css';

const windowWidth = ref(window.innerWidth);

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    required: true,
  },
  isSpecEB: {
    type: Boolean,
    required: true,
  },
  chassisDescriptionKey: {
    type: String,
    required: false,
    default: '',
  },
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const groundClearance = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'groundClearance');
});

const airSuspensionFueltanks = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'airsuspensionFueltanks');
});

const fuelTanks = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'fuelTanks');
});

const tyreSupplier = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'tyreSupplier');
});
const spareWheel = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'spareWheel');
});
const rimMaterial = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'rimMaterial');
});
</script>

<template>
  <section
    v-if="props.items"
    id="chassis"
    class="configurator-section"
  >
    <h2>{{ $i18n('chassisHeaderTitle') }}</h2>
    <template v-if="props.isSpecEB">
      <div
        v-if="fuelTanks && props.images.isSide"
        class="configurator-section__image is-side-image-custom-margin"
      >
        <img
          :src="props.images.isSide"
          alt="Truck - side view"
        >
      </div>
      <ConfigurationRadioSelection
        v-if="fuelTanks"
        v-model="store.selected.chassis[fuelTanks.id]"
        :type="fuelTanks.id"
        :options="fuelTanks.options"
        :title="fuelTanks.title"
        @select="emit('select', $event)"
      />
    </template>
    <template v-else>
      <ConfigurationRadioSelection
        v-if="airSuspensionFueltanks"
        v-model="store.selected.chassis[airSuspensionFueltanks.id]"
        :type="airSuspensionFueltanks.id"
        :options="airSuspensionFueltanks.options"
        :title="airSuspensionFueltanks.title"
        @select="emit('select', $event)"
      />
    </template>
    <h3
      v-if="props.images.side && props.images.isSide"
    >
      {{ $i18n('dimensionsTitle') }}
    </h3>
    <div class="configurator-section__row configurator-section__image-container">
      <div
        v-if="props.images.side"
        class="configurator-section__image side-image-custom-margin"
      >
        <img
          :src="props.images.side"
          alt="Truck - side view"
        >
      </div>
      <div
        v-if="props.chassisDescriptionKey"
        class="configurator-section__image-text"
        v-html="$i18n(props.chassisDescriptionKey)"
      />
    </div>
    <ConfigurationRadioSelection
      v-if="groundClearance"
      v-model="store.selected.chassis[groundClearance.id]"
      :type="groundClearance.id"
      :options="groundClearance.options"
      :title="groundClearance.title"
      @select="emit('select', $event)"
    />
    <div class="configurator-section__row">
      <ConfigurationRadioSelection
        v-if="tyreSupplier"
        v-model="store.selected.chassis[tyreSupplier.id]"
        :type="tyreSupplier.id"
        :options="tyreSupplier.options"
        :title="tyreSupplier.title"
        @select="emit('select', $event)"
      />
      <template v-if="spareWheel">
        <div class="sdds-divider-dark-vertical" />
        <ConfigurationCheckSelection
          v-model="store.selected.chassis[spareWheel.id]"
          :type="spareWheel.id"
          :title="spareWheel.title"
          :options="spareWheel.options"
          @select="emit('select', $event)"
        />
      </template>
    </div>
    <div
      v-if="rimMaterial"
      class="configurator-section__row"
    >
      <template v-if="rimMaterial">
        <ConfigurationCheckSelection
          v-model="store.selected.chassis[rimMaterial.id]"
          :type="rimMaterial.id"
          :title="rimMaterial.title"
          :options="rimMaterial.options"
          @select="emit('select', $event)"
        />
      </template>
    </div>
  </section>
</template>

<style scoped>
.sdds-divider-dark-vertical {
  height: var(--sdds-spacing-layout-72);
  margin-top: var(--sdds-spacing-element-48);
  align-self: center;
}

.configurator-section__image {
  max-width: 70%;
}

.configurator-section__image>img {
  max-width: 100%;
  object-fit: cover;
}

.is-side-image-custom-margin,
.side-image-custom-margin {
  margin-top: -12rem;
}

@media only screen and (max-width: 1294px) {
  .configurator-section__image>img {
    height: initial;
  }

  .is-side-image-custom-margin,
  .side-image-custom-margin {
    margin-top: -10rem;
  }
}

@media only screen and (max-width: 1000px) {
  .configurator-section__image {
    width: 90%;
  }

  .sdds-divider-dark-vertical {
    display: none;
  }

  .configurator-section__row {
    flex-direction: column;
    align-items: center;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import ConfigurationCheckSelection from '../ConfigurationCheckSelection';
import ConfigurationPackageInformation from '../ConfigurationPackageInformation';
import ConfigurationPackageInformationOptional from '../ConfigurationPackageInformationOptional';
import { updateSelections } from '../../services/digitalSalesService';
import { useMainStore } from '../../store/index.js';
import { useConfiguratorApi } from '../../composables/configurator';

import '../../styles/configurator-section.css';

const store = useMainStore();
const { repairMaintenanceBasePrice } = useConfiguratorApi();
const repairMaintenanceSelected = ref(store.selected?.repairMaintenance?.repairMaintenancePackage?.id === 'with');

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
});

const tachograph = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'repairMaintenanceTachograph');
});

const rmPackage = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'repairMaintenancePackage');
});

const procare = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'repairMaintenanceProcare');
});

const tachographDisabled = computed(() => {
  return !repairMaintenanceSelected.value;
});

const rmPackageRequired = computed(() => {
  return (store.market === 'pl' && store.selectedPaymentType === 'leasing') || (rmPackage.value?.selectionType !== 'checkbox');
});

const showRmOptions = computed(() => {
  return store.selectedPaymentType === 'cash';
});

const handleClick = (eventData) => {
  repairMaintenanceSelected.value = eventData.selected;
  if (eventData.selected) {
    store.selected.repairMaintenance.repairMaintenancePackage = rmPackage.value.options.find(o => o.id === 'with');
    if (!!tachograph.value && !eventData.maintainState) store.selected.repairMaintenance.repairMaintenanceTachograph = tachograph.value.options.find(o => o.id === 'without');
  } else {
    store.selected.repairMaintenance.repairMaintenancePackage = rmPackage.value.options.find(o => o.id === 'without');
    if (!!tachograph.value) store.selected.repairMaintenance.repairMaintenanceTachograph = tachograph.value.options.find(o => o.id === 'without');
  }
  updateSelections(store.selected).then(res => {
  }).catch(err => {
    console.log('update selections error: ', err);
  });
};

</script>

<template>
  <section
    v-if="props.items"
    id="repairMaintenance"
    class="configurator-section"
  >
    <div class="configurator-section_title">
      <h2>{{ $i18n('repairMaintenanceHeaderTitle') }}</h2>
    </div>
    <template v-if="rmPackageRequired">
      <ConfigurationPackageInformation
        v-if="rmPackage"
        :item="rmPackage"
        :price-override="repairMaintenanceBasePrice"
        :offer-text="rmPackage.offerText || null"
        :show-rm-options="showRmOptions"
        @loadeddata="handleClick($event)"
      />
    </template>
    <template v-else>
      <ConfigurationPackageInformationOptional
        v-if="rmPackage"
        :item="rmPackage"
        :price-override="repairMaintenanceBasePrice"
        :offer-text="rmPackage.offerText || null"
        :show-rm-options="showRmOptions"
        :selected="repairMaintenanceSelected"
        :checkbox="rmPackage.selectionType === 'checkbox'"
        @click="($event) => { rmPackage.selectionType === 'checkbox' ? handleClick($event) : null }"
      />
    </template>
    <div class="configurator-section__row">
      <template v-if="tachograph">
        <div
          v-if="tachographDisabled"
          class="tachograph-calibration-banner sdds-banner sdds-banner-info el-margin-bottom-8"
        >
          <div class="sdds-banner-body">
            <div class="sdds-banner-subheader">
              {{ $i18n('tachographCalibrationNeedToBeSelected') }}
            </div>
          </div>
        </div>
        <ConfigurationCheckSelection
          v-model="store.selected.repairMaintenance[tachograph.id]"
          :type="tachograph.id"
          :options="tachograph.options"
          :disabled="tachographDisabled"
          @select="emit('select', $event)"
        />
      </template>
      <template v-if="procare">
        <div class="sdds-divider-dark-vertical" />
        <ConfigurationCheckSelection
          v-model="store.selected.repairMaintenance[procare.id]"
          :type="procare.id"
          :options="procare.options"
          :offer-text="procare.offerText || null"
          @select="emit('select', $event)"
        />
      </template>
    </div>
  </section>
</template>

<style scoped>
  .rm-package-select {
    max-width: 70em;
  }
  .configurator-section_text {
    max-width: 70em;
  }
  .configurator-section_title {
    text-align: center;
  }
  .tachograph-calibration-banner {
    max-width: 30em;
  }
  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);
    align-self: center;
  }
  @media only screen and (max-width: 1000px) {
    .sdds-divider-dark-vertical {
      display: none;
    }
    .configurator-section__row {
      flex-direction: column;
    }
  }
</style>

<script setup>
import { computed } from 'vue';
import ConfigurationCheckSelection from '../ConfigurationCheckSelection';
import SelectOptionInfo from '../SelectOptionInfo';
import { useMainStore } from '../../store/index.js';

import '../../styles/configurator-section.css';
import '../../styles/configurator-selection.css';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    default: null,
  },
});

const fuelEfficiencyPackage = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'fuelEfficiencyPackage');
});

const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});
</script>

<template>
  <section
    v-if="props.items"
    id="fuel-efficiency"
    class="configurator-section"
  >
    <h2>{{ $i18n('fuelEfficiencyHeaderTitle') }}</h2>
    <div class="configurator-section__row">
      <div class="configurator-section__image">
        <img
          :src="props.images?.view"
          alt="Truck - front side view"
        >
      </div>
      <template v-if="specType !== 'stock_vehicle'">
        <ConfigurationCheckSelection
          v-if="fuelEfficiencyPackage"
          v-model="store.selected.fuelEfficiency[fuelEfficiencyPackage.id]"
          :type="fuelEfficiencyPackage.id"
          :options="fuelEfficiencyPackage.options"
          @select="emit('select', $event)"
        />
      </template>
      <template v-if="specType === 'stock_vehicle'">
        <div
          v-if="fuelEfficiencyPackage"
          class="configurator-selection"
        >
          <div class="configurator-selection__checkoption">
            <SelectOptionInfo
              :checkbox="true"
              :option="fuelEfficiencyPackage.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<style scoped>
  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);
    align-self: center;
  }
  .configurator-section__image img {
    width: 80%;
    object-fit: cover;
  }

  .configurator-section__row {
    place-items: center;
  }
  .configurator-selection {
    width: 80%;
  }
  .configurator-selection__checkoption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 30em;
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 1294px) {
    .configurator-section__image {
      width: 90vw;
    }
    .configurator-section__row {
      flex-direction: column;
    }
  }
</style>

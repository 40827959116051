<script setup>
import { useMainStore } from '../store/index.js';
import SimpleSlider from './SimpleSlider';

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  header: {
    type: String,
    default: '',
  },
  headerHelperText: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  showSliderTooltipValue: {
    type: Boolean,
    default: false,
  },
  sliderTooltipValue: {
    type: String,
    default: '',
  },
  sliderData: {
    type: Object,
    default () {
      return null;
    },
  },
});

</script>
<template>
  <div class="flex-container">
    <h5 class="flex-grow">
      {{ $i18n(header) }}
      <template v-if="headerHelperText !== ''">
        ({{ $i18n(headerHelperText) }})
      </template>
    </h5>
  </div>
  <div class="flex-container">
    <div class="item-description">
      {{ $i18n(description) }}
      <span
        v-if="showSliderTooltipValue"
        class="sdds-detail-07"
      >({{ sliderTooltipValue }})</span>
    </div>
    <div class="flex-grow">
      <SimpleSlider
        v-if="sliderData"
        v-model="store.selected.leasing[sliderData.id]"
        :type="sliderData.id"
        :options="sliderData.options"
        :show-tooltip="false"
        value-property="description"
        @select="emit('select', $event)"
      />
    </div>
  </div>
</template>

<style scoped>
  h5 {
    margin-bottom: var(--sdds-spacing-element-8);
  }
  .flex-container {
    display: flex;
    align-items: center;
  }
  .flex-container > div {
    margin-right: var(--sdds-spacing-element-24);
  }
  .flex-container > div:nth-child(2) {
    margin-right: var(--sdds-spacing-element-48);
  }
  .flex-grow {
    flex-grow: 1;
  }
</style>

<script setup>
import { computed } from 'vue';
import InformationIcon from './InformationIcon';
import { useConfiguratorApi } from '../composables/configurator';
import { useMainStore } from '../store/index.js';

const { servicesPrice, repairMaintenanceBasePriceOptional, totalPrice } = useConfiguratorApi();
const store = useMainStore();


const props = defineProps({
  selectorId: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: 'top',
  },
  includeSelectedVehicles: {
    type: Boolean,
    default: false,
  },
});

// price of selected service
const servicePrice = (service) => servicesPrice.value[service];
// Sum of prices for all services
const servicesPriceSum = computed(() => Object.values(servicesPrice.value).reduce((previousValue, currentValue) => previousValue + currentValue));

const numberVehicles = computed(() => props.includeSelectedVehicles ? store.selectedNumberVehicles : 1);
</script>

<template>
  <sdds-tooltip
    :placement="props.placement"
    :selector="`#${selectorId}`"
    mouse-over-tooltip="true"
  >
    <ul>
      <li><strong>{{ $i18n('services') }}:</strong> {{ servicesPriceSum * numberVehicles }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffixMonthly) }}</span></li>
      <li>- {{ $i18n('connectedServicesHeaderTitle') }}: {{ servicePrice('connectedServices') * numberVehicles }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffixMonthly) }}</span></li>
      <li>- {{ $i18n('driverServicesHeaderTitle') }}: {{ servicePrice('driverServices') * numberVehicles }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffixMonthly) }}</span></li>
    </ul>
    <br>
    <ul>
      <li><strong>{{ $i18n('repairMaintenanceHeaderTitle') }}:</strong> {{ repairMaintenanceBasePriceOptional * numberVehicles }}&nbsp;<span class="sdds-detail-05">{{ $i18n(totalPrice.priceSuffixMonthly) }}</span></li>
      <template v-if="store.showYearEndOfferBanner">
        <li><span style="font-size: x-small;">{{ $i18n('yearEndOfferPriceHelperText2') }}</span></li>
      </template>
    </ul>
  </sdds-tooltip>
  <InformationIcon :id="selectorId" />
</template>

<style scoped>
  sdds-tooltip > ul {
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    white-space: pre-wrap;
  }
</style>

<script setup>
import { computed, inject } from 'vue';
import ConfigurationRadioSelection from '../ConfigurationRadioSelection';
import ConfigurationSliderSelection from '../ConfigurationSliderSelection';
import SelectOptionInfo from '../SelectOptionInfo';
import { useMainStore } from '../../store/index.js';

import '../../styles/configurator-section.css';
import '../../styles/configurator-selection.css';

const i18n = inject('i18n');

const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

const store = useMainStore();

const emit = defineEmits(['select']);

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    default: null,
  },
});

const fifthWheel = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'fifthWheel');
});

const mountingPlate = computed(() => {
  if (!props.items) return null;
  return props.items.find(x => x.id === 'mountingPlate');
});

const image = computed(() => {
  return store.selected.fifthWheel[mountingPlate.value.id].descriptionImage;
});

const mountingPlateOptions = computed(() => {
  if (mountingPlate.value?.options) {
    return mountingPlate.value.options?.map(o => {
      o.description = i18n(o.value);
      o.value = i18n(mountingPlate.value.title);
      return o;
    });
  } else return [];
});

const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});

</script>

<template>
  <section
    v-if="props.items"
    id="fifth-wheel"
    class="configurator-section"
  >
    <h2>{{ $i18n('fifthWheelHeaderTitle') }}</h2>
    <div class="configurator-section__row">
      <template v-if="mountingPlate && specType !== 'stock_vehicle'">
        <ConfigurationRadioSelection
          v-if="fifthWheel"
          v-model="store.selected.fifthWheel[fifthWheel.id]"
          :title="fifthWheel.title"
          :type="fifthWheel.id"
          :options="fifthWheel.options"
          @select="emit('select', $event)"
        />
      </template>
      <template v-if="mountingPlate && specType === 'stock_vehicle'">
        <div
          v-if="fifthWheel"
          class="configurator-selection"
        >
          <h3
            v-if="fifthWheel.title"
            class="configurator-selection__title"
          >
            {{ $i18n(fifthWheel.title) }}
          </h3>
          <div
            class="configurator-selection__options"
            :class="{ 'justify-content-center': true }"
          >
            <SelectOptionInfo
              :option="fifthWheel.options[0]"
              :selected="true"
              :show-gap-above-description="true"
            />
          </div>
        </div>
      </template>
      <div class="sdds-divider-dark-vertical" />
      <div class="configurator-section__column">
        <ConfigurationSliderSelection
          v-if="mountingPlate && specType !== 'stock_vehicle'"
          v-model="store.selected.fifthWheel[mountingPlate.id]"
          :title="mountingPlate.title"
          :type="mountingPlate.id"
          :options="mountingPlate.options"
          :show-slider-extreme-values="true"
          :show-tooltip="false"
          @select="emit('select', $event)"
        />
        <template v-if="mountingPlate && specType === 'stock_vehicle'">
          <div class="configurator-selection">
            <h3
              v-if="mountingPlate.title"
              class="configurator-selection__title"
            >
              {{ $i18n(mountingPlate.title) }}
            </h3>
            <div
              class="configurator-selection__options"
              :class="{ 'justify-content-center': true }"
            >
              <SelectOptionInfo
                :option="mountingPlateOptions[0]"
                :selected="true"
                :show-description-image="false"
                :show-description-text-as-value="true"
              />
            </div>
          </div>
        </template>
        <div class="configurator-section__image">
          <img
            :src="`${staticImagesBaseUrl}/${image}`"
            alt="Truck - front side view"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .configurator-section__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);
    margin-top: var(--sdds-spacing-element-48);
    align-self: center;
  }
  .configurator-section__image img {
    max-width: 90%;
  }

  @media only screen and (max-width: 1000px) {
    .sdds-divider-dark-vertical {
      display: none;
    }
    .configurator-section__row {
      flex-direction: column;
    }
    .configurator-section__image img {
      max-width: 50%;
    }
  }
</style>

<script setup>
  import PL_StandardA4X2NA from './pl/StandardA4X2NA.vue';
  import PL_StandardA4X2EB from './pl/StandardA4X2EB.vue';
  import DE_StandardA4X2NA from './de/StandardA4X2NA.vue';
  import DE_StandardA4X2EB from './de/StandardA4X2EB.vue';

  const emit = defineEmits(['select']);

  defineProps({
    offerTemplate: {
      type: String,
      default: '',
    },
    specType: {
      type: String,
      default: '',
    },
    isFrameAgreement: {
      type: Boolean,
      default: false,
    },
  });

  const componentMapping = {
    'PL_STANDARDA4X2EB': PL_StandardA4X2EB,
    'PL_STANDARDA4X2NA': PL_StandardA4X2NA,
    'PL_INDEX': PL_StandardA4X2NA,
    'DE_STANDARDA4X2EB': DE_StandardA4X2EB,
    'DE_STANDARDA4X2NA': DE_StandardA4X2NA,
    'DE_INDEX': DE_StandardA4X2NA,
  };

  const handleOptionSelect = (object, section) => {
    emit('select', object, section);
  };
</script>

<template>
  <component
    :is="componentMapping[offerTemplate]"
    :offer-template="offerTemplate"
    :spec-type="specType"
    :is-frame-agreement="isFrameAgreement"
    @select="handleOptionSelect"
  />
</template>

<script setup>
  import { computed } from 'vue';
  import PriceSuffix from '../../PriceSuffix.vue';
  import { useConfiguratorApi } from '../../../composables/configurator';

  const emit = defineEmits(['select']);

  const props = defineProps({
    option: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    selectedPaymentType: {
      type: String,
      required: true,
    },
  });

  const { getPrice, totalFormattedPrice } = useConfiguratorApi();

  const price = computed(() => {
    return getPrice(props.option);
  });

  const isMonthlyCost = computed(() => {
    if (props.option.priceType === 'monthlyCost') return true;
    return false;
  });
</script>

<template>
  <div
    class="configurator-selection__color-option"
    :class="{ selected }"
    @click="emit('select', option)"
  >
    <h5>{{ $i18n(option.value) }}</h5>
    <div
      class="configurator-selection__color-option--circle"
      :style="`background-color: ${option.description}`"
    />
    <div class="configurator-selection__color-option-cost">
      <span class="configurator-selection__color-option-price">+ {{ price.toLocaleString('en').replace(/,/g,' ') }}</span>&nbsp;<PriceSuffix :price-suffix="props.selectedPaymentType === 'cash' && !isMonthlyCost ? totalFormattedPrice.priceSuffixOneTime : totalFormattedPrice.priceSuffixMonthly" />
    </div>
  </div>
</template>

<style scoped>
  h5 {
    margin: 0;
    text-align: center;
  }
  .configurator-selection__color-option {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: var(--sdds-spacing-element-8);
  }
    .configurator-selection__color-option:hover {
      cursor: pointer;
    }
    .configurator-selection__color-option.selected > .configurator-selection__color-option--circle {
      border-color: var(--sdds-blue);
    }

    .configurator-selection__color-option--circle {
      width: 4em;
      height: 4em;
      border-radius: 50%;
      border: 3px solid transparent;
      transition: border-color 0.2s ease-out, outline 0.1s ease-out;
    }
    .configurator-selection__color-option:hover:not(.selected) > .configurator-selection__color-option--circle {
      border-color: var(--sdds-grey-300);
    }

    .configurator-selection__color-option-cost {
      font-size: 1.6em;
      vertical-align: top;
      font-family: "Scania Sans Regular", Arial, Helvetica, sans-serif;
      color: var(--sdds-grey-600);
      text-align: center;
    }
      .configurator-selection__color-option-price {
        color: var(--sdds-grey-500);
      }
      .priceSuffix__container {
        font-size: 0.4em;
        line-height: 1em;
              text-align: left;
      }
      .configurator-selection__color-option-price--included {
        font-size: 0.7em;
        color: var(--sdds-grey-500);
      }
</style>

<script setup>
/* eslint-disable vue/no-v-html */
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  class: {
    type: String,
    default: null,
  },
});

const safelyParseMarkdown = (markdownString) => {
  return sanitizeHtml(marked.parse(markdownString));
};

</script>

<template>
  <div
    :class="props.class"
    v-html="safelyParseMarkdown(props.text)"
  />
</template>

<script setup>
  import { computed } from 'vue';
  import ConfigurationRadioSelectionV2 from '../ConfigurationRadioSelectionV2';
  import ConfigurationCheckSelection from '../ConfigurationCheckSelection';
  import { useMainStore } from '../../store/index.js';
  import '../../styles/configurator-section.css';
  import '../../styles/configurator-selection.css';

  const store = useMainStore();

  const emit = defineEmits(['select']);

  const props = defineProps({
    items: {
      type: Object,
      default: null,
    },
    images: {
      type: Object,
      default: null,
    },
  });

  const colourScheme = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'colourScheme');
  });
  const betterLivingPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'betterLivingPackage');
  });
  const driverComfortPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'driverComfortPackage');
  });
  const bedPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'bedPackage');
  });
  const winterOptionsPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'winterOptionsPackage');
  });
  const airConditioningOptionsPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'airConditioningOptionsPackage');
  });
  const kitchenOptionsPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'kitchenOptionsPackage');
  });
  const safetyOptionsPackage = computed(() => {
    if (!props.items) return null;
    return props.items.find(x => x.id === 'safetyOptionsPackage');
  });

  const additionalOptions = computed(() => {
    const groupSize = 2;
    const options = [];
    if (driverComfortPackage.value) options.push(driverComfortPackage.value);
    if (bedPackage.value) options.push(bedPackage.value);
    if (winterOptionsPackage.value) options.push(winterOptionsPackage.value);
    if (airConditioningOptionsPackage.value) options.push(airConditioningOptionsPackage.value);
    if (kitchenOptionsPackage.value) options.push(kitchenOptionsPackage.value);
    if (safetyOptionsPackage.value) options.push(safetyOptionsPackage.value);
    return Array.from({ length: Math.ceil(options.length / groupSize) }, (_, i) => options.slice(i * groupSize, i * groupSize + groupSize));
  });
</script>

<template>
  <section
    v-if="props.items"
    id="interior"
    class="configurator-section"
  >
    <h2>{{ $i18n('interiorHeaderTitle') }}</h2>
    <div class="configurator-section__image">
      <img
        :src="props.images?.dashboard"
        alt="Truck - front side view"
      >
      <img
        :src="props.images?.interior"
        alt="Truck - front side view"
      >
    </div>
    <template v-if="colourScheme">
      <h3>{{ $i18n(colourScheme.title) }}</h3>
      <ConfigurationRadioSelectionV2
        v-model="store.selected.interior[colourScheme.id]"
        :type="colourScheme.id"
        :options="colourScheme.options"
        @select="emit('select', $event)"
      />
    </template>
    <template v-if="betterLivingPackage">
      <h3>{{ $i18n(betterLivingPackage.title) }}</h3>
      <ConfigurationRadioSelectionV2
        v-if="betterLivingPackage"
        v-model="store.selected.interior[betterLivingPackage.id]"
        :type="betterLivingPackage.id"
        :options="betterLivingPackage.options"
        @select="emit('select', $event)"
      />
    </template>
    <template v-if="additionalOptions.length">
      <h3>{{ $i18n('additionalOptions') }}</h3>
      <template
        v-for="(packageGroup, index) in additionalOptions"
        :key="index"
      >
        <div class="configurator-section__row">
          <template
            v-for="(packageObject, index2) in packageGroup"
            :key="index2"
          >
            <div
              v-if="index2 > 0"
              class="sdds-divider-dark-vertical"
            />
            <ConfigurationCheckSelection
              v-model="store.selected.interior[packageObject.id]"
              :type="packageObject.id"
              :options="packageObject.options"
              :inline-style="{ 'width': '30em' }"
              @select="emit('select', $event)"
            />
          </template>
        </div>
      </template>
    </template>
  </section>
</template>

<style scoped>
  .configurator-section__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
    max-width: 100%;
  }
  .configurator-section__image img {
    width: 40%;
  }
  .sdds-divider-dark-vertical {
    height: var(--sdds-spacing-layout-128);
    align-self: center;
  }
  .configurator-selection__checkoption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 30em;
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 1000px) {
    .configurator-section__image {
      gap: 1em;
      max-width: 90%;
    }
    .sdds-divider-dark-vertical {
      display: none;
    }
    .configurator-section__row {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 500px) {
    .configurator-section__image {
      flex-direction: column;
      align-items: center;
    }
    .configurator-section__image img {
      width: 90%;
    }
  }
</style>

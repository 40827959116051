<script setup>
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';

SwiperCore.use([Autoplay, Pagination]);

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
});

</script>
<template>
  <swiper
    :loop="true"
    :pagination="{ clickable: true }"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
  >
    <swiper-slide
      v-for="sl in props.slides"
      :key="sl.alt"
    >
      <img
        :src="sl.src"
        :alt="sl.alt"
      >
    </swiper-slide>
  </swiper>
</template>
<style scoped>
.swiper-slide>img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-width: 99%;
}

.swiper {
  height: 85%;
}
</style>

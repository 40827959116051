<script setup>
import { computed } from 'vue';
import { useMainStore } from '../store/index.js';
import { useConfiguratorApi } from '../composables/configurator';
import InformationIcon from './InformationIcon';

const store = useMainStore();
const { totalPrice, getRegularPrice, getRepairMaintenancePrice } = useConfiguratorApi();

const componentSelections = computed(() => {
  let selections = [];
  Object.entries(store.selected).filter(x => !['leasing', 'payment'].includes(x[0])).forEach((entry) => {
    const [section, sectionItems] = entry;
    Object.entries(sectionItems).forEach((items) => {
      const [selection, selectionItem] = items;
      const price = getRegularPrice(selectionItem.price);
      if (price !== 0) selections.push({ name: selection, price });
    });
  });
  return selections;
});

const repairMaintenanceSelections = computed(() => {
  let selections = [];
  Object.entries(store.selected).filter(x => !['leasing', 'payment'].includes(x[0])).forEach((entry) => {
    const [section, sectionItems] = entry;
    Object.entries(sectionItems).forEach((items) => {
      const [selection, selectionItem] = items;
      const price = getRepairMaintenancePrice(selectionItem.repairMaintenancePrice);
      if (price !== 0) selections.push({ name: selection, price });
    });
  });
  return selections;
});
</script>

<template>
  <sdds-tooltip
    placement="top"
    selector="#price-breakdown-tooltip"
    mouse-over-tooltip="true"
  >
    <strong>Base price:</strong> {{ totalPrice.priceBreakdown.basePrice }} <span class="sdds-detail-07">{{ $i18n(totalPrice.priceSuffix) }}</span><br>
    <strong>Component selections:</strong> {{ totalPrice.priceBreakdown.selectedProductOptionsPrice + totalPrice.priceBreakdown.selectedServiceOptionsPrice }}&nbsp;<span class="sdds-detail-07">{{ $i18n(totalPrice.priceSuffix) }}</span><br>
    <span
      v-for="selection in componentSelections"
      :key="selection.name"
    >
      {{ selection.name }}: {{ selection.price }}&nbsp;<span class="sdds-detail-07">{{ $i18n(totalPrice.priceSuffix) }}</span> | 
    </span>
    <strong>R&M:</strong> {{ totalPrice.priceBreakdown.repairMaintenancePrice }}&nbsp;<span class="sdds-detail-07">{{ $i18n(totalPrice.priceSuffix) }}</span><br>
    <strong>R&M selections:</strong> {{ totalPrice.priceBreakdown.selectedOptionsRepairMaintenancePrice }}&nbsp;<span class="sdds-detail-07">{{ $i18n(totalPrice.priceSuffix) }}</span><br>
    <span
      v-for="selection in repairMaintenanceSelections"
      :key="selection.name"
    >
      {{ selection.name }}: {{ selection.price }}&nbsp;<span class="sdds-detail-07">{{ $i18n(totalPrice.priceSuffix) }}</span> | 
    </span>
  </sdds-tooltip>
  <InformationIcon id="price-breakdown-tooltip" />
</template>

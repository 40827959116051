import { createApp } from 'vue';
import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';
import App from './App';
import { createPinia } from 'pinia';
import { createRouter } from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import keycloakAuth from './plugins/keycloakAuth';
import adobeLaunchEventHandler from './plugins/adobeLaunchEventHandler';
import { useMainStore } from './store';
import i18n from './plugins/i18n';
import { addTrackingEvent } from './services/digitalSalesService';
import { addCustomTrackingEvent } from './utils/customTracking';
import { datadogRum } from '@datadog/browser-rum';


import { faMagnifyingGlass, faSpinner, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
library.add(faMagnifyingGlass, faSpinner, faCircleInfo);

defineCustomElements();
addTheme(scania);

const app = createApp(App);
app.component('FontAwesomeIcon', FontAwesomeIcon);

app.use(createPinia());
app.use(keycloakAuth);

const params = new URLSearchParams(window.location.search);
const tid = params.get('tid');
const source = params.get('source');
const reload = params.get('reload') ?? false;

const initMain = () => {

  const kcInitOptions = {
    onLoad: 'login-required',
    pkceMethod: 'S256',
    checkLoginIframe: true,
  };

  app.$keycloak
    .init(kcInitOptions)
    .then((auth) => {
      if (!auth) {
        console.log('Not authenticated');
      } else {
        const store = useMainStore();
        app.use(i18n, store);
        store.token = app.$keycloak.token;
        store.isAdmin = app.$keycloak.hasResourceRole('ds-admin');
        store.isSuperUser = app.$keycloak.hasResourceRole('ds-superuser');
        store.isSalesman = app.$keycloak.hasResourceRole('ds-salesman');
        store.externalStaffReference = app.$keycloak.idTokenParsed.customer?.external_staff_reference;
        store.federationUserAccountId = app.$keycloak.idTokenParsed.sub;
        store.tid = tid;
        addCustomTrackingEvent('login');
        datadogRum.setUser({
          id: store.federationUserAccountId,
          name: app.$keycloak.idTokenParsed?.name,
          email: app.$keycloak.idTokenParsed?.email,
          isAdmin: store.isAdmin,
          isSuperUser: store.isSuperUser,
          isSalesman: store.isSalesman,
        });
      }
    }).finally(() => {
      const store = useMainStore();
      app.use(adobeLaunchEventHandler, store);
      const router = createRouter(app);
      app.use(router);

      app.mount('#app');
    });
};

const datadogSetup = () => {
  datadogRum.init({
    applicationId: '5189322c-823d-4268-a5ff-1deb26900fc1',
    clientToken: 'pub07908acb161c95c47fad68f537ea64dd',
    site: 'datadoghq.eu',
    service: 'digital-sales',
    env: process.env.VUE_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.VUE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

if (tid && source && !reload) {
  console.log('create event load');
  const data = {
    event: 'load',
    data: {
      timestamp: (new Date()).toISOString(),
      source,
    },
  };
  addTrackingEvent(data, tid)
    .catch(err => {
      console.error('failed to create tracking event', err);
    })
    .finally(() => {
      console.log('finish create event load');
      window.location.replace(window.location.href + '&reload=true');
    });
} else {
  console.log('initMain');
  if (process.env.VUE_APP_ENV === 'prod') datadogSetup();
  initMain();
}

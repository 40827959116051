<script setup>
import { computed, onMounted } from 'vue';
import { useConfiguratorApi } from '../composables/configurator';
import { useMainStore } from '../store/index';
import InformationIcon from './InformationIcon';
import InformationModal from './InformationModal';
import PriceSuffix from './PriceSuffix';
import MarkupSegment from './MarkupSegment';
import SimpleSlider from './SimpleSlider';
import { updateSelections } from '../services/digitalSalesService';

const store = useMainStore();
const emit = defineEmits(['update:modelValue', 'select', 'loadeddata']);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  priceOverride: {
    type: Number,
    default: null,
  },
  offerText: {
    type: String,
    default: null,
  },
  showRmOptions: {
    type: Boolean,
    default: false,
  },
});

const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

const { getPrice, totalFormattedPrice } = useConfiguratorApi();

const modalId = computed(() => {
  return `${props.item.id}-modal`;
});

const price = computed(() => {
  return getPrice(props?.item.options.find(x => x.default));
});

const rmOptions = computed(() => {
  return store.options.leasing.filter(o => ['contractLength', 'mileage'].includes(o.id));
});

let debounceTimer = null;
const debounceSelect = (object, section) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => updateSelections(store.selected).catch(err => console.log('update selections error: ', err)), 1500);
};

onMounted(() => {
  emit('loadeddata', { selected: true, maintainState: true });
});

</script>

<template>
  <div
    class="package-selection selected"
  >
    <div class="package-selection-header">
      <div class="package-selection-header__title">
        <h3>
          {{ $i18n(props.item.title) }}
          <span
            v-if="props.offerText"
            class="offerText"
          >
            {{ $i18n(props.offerText) }}
          </span>
        </h3>
      </div>
      <div
        class="package-selection-header__status-indicator package-selection-header__status-indicator--selected"
      >
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.744 8.48a1 1 0 0 1 .004 1.413 2709.096 2709.096 0 0 1-9.899 9.909 1642.18 1642.18 0 0 0-4.806 4.805 1 1 0 0 1-1.416.001l-7.355-7.362a1 1 0 0 1 1.415-1.414l6.646 6.653a2024.31 2024.31 0 0 1 5.35-5.342c2.362-2.356 5.155-5.146 8.647-8.66a1 1 0 0 1 1.414-.004Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div class="package-selection-content">
      <div class="package-selection-cost">
        <span class="package-selection-cost__price">+ {{ (priceOverride || price).toLocaleString('en').replace(/,/g,' ') }}</span>&nbsp;<PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixMonthly" />
      </div>
      <div class="package-selection-content__description">
        <img
          v-if="props.item.descriptionImage"
          :src="`${staticImagesBaseUrl}/${props.item.descriptionImage}`"
        >
        <MarkupSegment
          v-if="props.item.description"
          :text="$i18n(props.item.description)"
        />
      </div>
    </div>
    <div
      v-if="showRmOptions"
      class="mileage-contract-selection sdds-row sdds-u-flex"
      @click="(e) => e.stopPropagation()"
    >
      <div
        v-for="(sliderData, key) in rmOptions"
        :key="key"
        class="sdds-col-lg-6 sdds-col-xs-12"
      >
        <div class="flex-container">
          <h5 class="flex-grow">
            {{ $i18n(sliderData.title) }}
          </h5>
        </div>
        <div class="flex-container">
          <div class="item-description">
            {{ $i18n(store.selected.leasing[sliderData.id].description) }}
          </div>
          <div class="flex-grow">
            <SimpleSlider
              v-if="sliderData"
              v-model="store.selected.leasing[sliderData.id]"
              :type="sliderData.id"
              :options="sliderData.options"
              :show-tooltip="false"
              value-property="description"
              @select="debounceSelect($event, 'leasing')"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="props.item.information"
      :id="modalId"
      :data-analytics="`{'eventComponent':'configuration','eventType':'modal','eventLabel':'More information - ${$i18n(props.item.title, [], 'en-GB')} - ${props.item.id}'}`"
      class="package-selection-footer"
      :data-dd-action-name="`More information - ${$i18n(props.item.title, [], 'en-GB')} - ${props.item.id}`"
      @click.stop
    >
      <a
        href="#"
        @click.prevent
      >{{ $i18n('moreInformationText') }}</a>
      <InformationIcon />
    </div>
  </div>
  <teleport to="main">
    <InformationModal
      v-if="props.item.information"
      :id="modalId"
      :title="props.item.title"
      :information-text="props.item.information"
      :information-image="props.item.informationImage"
    />
  </teleport>
</template>

<style scoped>
  h3 { margin: 0; }

  .package-selection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 70em;
    border: 1px solid var(--sdds-grey-300);
    border-radius: 1rem;
    background-color: var(--sdds-white);
  }

  .package-selection.selected {
    border-color: var(--sdds-blue);
    border-width: 2px;
  }

  .package-selection-content {
    padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-16);
  }
  .package-selection-content__description {
    display: flex;
    flex-direction: row;
  }
  .package-selection-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3em;
    border-top: 1px solid var(--sdds-grey-300);
    padding: var(--sdds-spacing-element-8) var(--sdds-spacing-element-16);
    background-color: var(--sdds-blue-50);
    cursor: pointer;
  }
  .package-selection-footer > a {
    line-height: 2em;
    border: 0;
    text-decoration: underline;
  }
  .package-selection-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-16);
  }
  .package-selection-header__status-indicator {
    flex: 0 0 1.8em;
    height: 1.8em;
    border: 1px solid var(--sdds-grey-300);
    border-radius: 50%;
    color: var(--sdds-white);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.6px;
    padding: 0.2em;
    margin-left: 1em;
    -webkit-transition: background-color 0.1s ease-out;
    -moz-transition: background-color 0.1s ease-out;
    -ms-transition: background-color 0.1s ease-out;
    -o-transition: background-color 0.1s ease-out;
    transition: background-color 0.1s ease-out;
  }
  .package-selection-header__status-indicator--selected {
    background-color: var(--sdds-blue);
  }
  .package-selection-header__status-indicator {
    flex: 0 0 1.8em;
    height: 1.8em;
    border: 1px solid var(--sdds-grey-300);
    border-radius: 50%;
    color: var(--sdds-white);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.6px;
    padding: 0.2em;
    margin-left: 1em;
    cursor: pointer;
  }
  .package-selection-header__status-indicator--checkbox {
    border-radius: 0;
  }
  .package-selection-header__status-indicator--selected {
    background-color: var(--sdds-blue);
  }
  .package-selection-cost {
    font-size: 2em;
    vertical-align: top;
    font-family: "Scania Sans Regular", Arial, Helvetica, sans-serif;
    color: var(--sdds-grey-600);
  }
  .package-selection-cost__price {
    color: var(--sdds-grey-500);
  }
  .package-selection-cost__price--included {
    font-size: 0.7em;
    color: var(--sdds-grey-500);
  }
  .priceSuffix__container {
    font-size: 0.4em;
    line-height: 1em;
  }
  .mileage-contract-selection {
    padding: 0 var(--sdds-spacing-element-16) var(--sdds-spacing-element-16) var(--sdds-spacing-element-16);
    border-top: 1px solid var(--sdds-divider-light);
    cursor: default;
  }
  .mileage-contract-selection .flex-container {
    display: flex;
    align-items: center;
  }
  .mileage-contract-selection .flex-container > div {
    margin-right: var(--sdds-spacing-element-24);
  }
  .mileage-contract-selection .flex-grow {
    flex-grow: 1;
  }
  .offerText {
    background: #D95610;
    padding: 5px 15px;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    margin-left: 25px;
  }
  @media only screen and (max-width: 1294px) {
    .package-selection-content__description > img {
      display: none;
    }
    .package-selection {
      max-width:  90vw;
    }
  }
</style>

<script setup>
  import { inject, ref, computed } from 'vue';
  import { requestSupport } from '../services/digitalSalesService';
  import { useMainStore } from '../store/index';
  import { useRouter } from 'vue-router';

  const i18n = inject('i18n');

  const store = useMainStore();
  const keycloak = inject('keycloak');
  const adobeEvent = inject('adobeEvent');
  const router = useRouter();


  const contactPhone = ref('');
  const contactEmail = ref(keycloak.idTokenParsed.email);
  const requestingSupportProcessing = ref(false);
  const requestingSupportError = ref(false);
  const displayModal = ref(false);
  const displayEmphasizer = ref(true);

  const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

  const sendSupportRequest = () => {
    requestingSupportProcessing.value = true;
    requestingSupportError.value = false;
    adobeEvent({ event: 'sendSupportRequest' });
    const restParams = ['ActiveOffersView', 'ActiveOffersView-Default'].includes(router.currentRoute.value.name) ? ['availableOffersPage'] : [];
    requestSupport(contactPhone.value, contactEmail.value, ...restParams)
      .then(() => {
        store.supportRequested = true;
      })
      .catch(() => {
        requestingSupportError.value = true;
      })
      .finally(() => {
        requestingSupportProcessing.value = false;
      });
  };

  const toggleDisplayModal = () => {
    displayModal.value = !displayModal.value;
    displayEmphasizer.value = false;
    if (displayModal.value) adobeEvent({ event: 'showContactMe' });
  };

  const showSupport = computed(()=> {
    return router.currentRoute.value.name !== 'SummaryView';
  });

  const imageUrl = computed(() => {
    const image = (i18n('imageUrlContactInfo') !== 'imageUrlContactInfo') ? i18n('imageUrlContactInfo') : 'contact_info.png';
    return`${staticImagesBaseUrl}/${image}`;
  });

</script>

<template>
  <div
    class="support-button-emphasizer"
    :class="{ 'hidden': !displayEmphasizer }"
  >
    <div class="support-button-emphasizer__close">
      <span
        data-analytics="{'eventComponent':'supportTooltip','eventType':'xmark','eventLabel':'close'}"
        @click.stop="displayEmphasizer = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.5 7.5a1 1 0 0 1 0 1.414L8.914 24.5A1 1 0 0 1 7.5 23.086L23.086 7.5a1 1 0 0 1 1.414 0Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 7.5a1 1 0 0 1 1.414 0L24.5 23.086a1 1 0 0 1-1.414 1.414L7.5 8.914a1 1 0 0 1 0-1.414Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div>
    <div class="contact__image">
      <img :src="imageUrl">
    </div>
    <span class="sdds-basic-01">{{ $i18n('needAssistance') }}</span>
  </div>
  <button
    v-if="showSupport"
    id="showSupportModalButton"
    data-analytics="{'eventComponent':'supportButton','eventType':'button','eventLabel':'show'}"
    class="support-button"
    data-dd-action-name="show"
    @click="toggleDisplayModal"
  >
    <svg
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.5274 8.38336C19.6208 8.38336 8.3501 19.6561 8.3501 33.5607C8.3501 47.4674 19.6208 58.7381 33.5274 58.7381C47.432 58.7381 58.7048 47.4674 58.7048 33.5607C58.7048 19.6561 47.432 8.38336 33.5274 8.38336ZM4.1626 33.5607C4.1626 17.3425 17.3093 4.19586 33.5274 4.19586C49.7456 4.19586 62.8923 17.3446 62.8923 33.5607C62.8923 49.7789 49.7435 62.9256 33.5274 62.9256C17.3093 62.9256 4.1626 49.7789 4.1626 33.5607Z"
        fill="white"
      />
      <path
        d="M30.2546 38.7511C30.2546 37.744 30.4535 36.8835 30.8451 36.1716C31.2387 35.4346 31.938 34.6369 32.9472 33.7764L34.6431 32.338C35.5016 31.603 36.1025 30.9268 36.4458 30.3112C36.7913 29.6726 36.963 28.9503 36.963 28.1379V27.6584C36.963 26.6765 36.6803 25.9018 36.115 25.3365C35.5748 24.7481 34.7897 24.4529 33.7575 24.4529C31.6197 24.4529 30.441 25.7301 30.219 28.2845H25.1333C25.2799 26.0253 26.0546 24.1954 27.4574 22.7946C28.856 21.3939 30.9183 20.6946 33.6465 20.6946C36.3726 20.6946 38.4244 21.3583 39.8021 22.6837C41.2007 24.0111 41.9 25.7552 41.9 27.918C41.9 29.4402 41.5818 30.73 40.9432 31.7873C40.3046 32.8426 39.1845 34.0841 37.589 35.5079L36.4458 36.5401C36.0061 36.9589 35.7214 37.4111 35.6 37.9031C35.4659 38.4703 35.4039 39.052 35.4157 39.6347H30.2546V38.7511ZM30.219 42.3628H35.489V47.3732H30.219V42.3628Z"
        fill="white"
      />
    </svg>
  </button>
  <div
    class="sdds-modal-backdrop show"
    :class="{ 'hide': !displayModal }"
  >
    <div class="sdds-modal sdds-modal-sm">
      <div class="sdds-modal-header">
        <h3 v-if="!store.supportRequested">
          {{ $i18n('supportRequestHeader') }}
        </h3>
        <h3 v-if="store.supportRequested">
          {{ $i18n('supportRequestConfirmationHeader') }}
        </h3>
        <span
          data-analytics="{'eventComponent':'supportModal','eventType':'xmark','eventLabel':'close'}"
          class="sdds-modal-btn"
          @click="toggleDisplayModal"
        />
      </div>
      <div class="sdds-modal-body">
        <template v-if="!store.supportRequested">
          <div class="contact__image">
            <img :src="imageUrl">
          </div>
          <p class="sdds-body-01">
            {{ $i18n('supportRequestText') }}
          </p>
          <div>
            <label for="phoneNumberInput">
              {{ $i18n('phone') }}
            </label>
            <input
              id="phoneNumberInput"
              v-model="contactPhone"
              class="sdds-textfield-input"
              type="tel"
              autofocus
              :placeholder="$i18n('phonePlaceholder')"
            >
          </div>
          <div>
            <label for="mailInput">
              {{ $i18n('email') }}
            </label>
            <input
              id="mailInput"
              v-model="contactEmail"
              class="sdds-textfield-input"
              type="email"
              autofocus
              :placeholder="$i18n('emailPlaceholder')"
            >
          </div>
        </template>
        <template v-if="store.supportRequested">
          <p class="sdds-body-01">
            {{ $i18n('supportRequestConfirmation') }}
          </p>
        </template>
      </div>
      <div class="sdds-modal-actions">
        <div
          v-if="requestingSupportError"
          class="sdds-banner sdds-banner-error margin-bottom-24"
        >
          <div class="sdds-banner-body">
            <h6 class="sdds-banner-header">
              {{ $i18n('supportRequestConfirmationError') }}
            </h6>
          </div>

          <div
            class="sdds-banner-close"
            @click="requestingSupportError=false"
          />
        </div>
        <button
          data-analytics="{'eventComponent':'supportModal','eventType':'button','eventLabel':'close'}"
          class="sdds-btn sdds-btn-secondary sdds-btn-md"
          data-dd-action-name="close"
          @click="toggleDisplayModal"
        >
          {{ $i18n('closeButtonText') }}
        </button>
        <button
          v-if="!store.supportRequested"
          data-analytics="{'eventComponent':'supportModal','eventType':'button','eventLabel':'contact me'}"
          class="sdds-btn sdds-btn-primary sdds-btn-md"
          :disabled="(!contactEmail && !contactPhone) || requestingSupportProcessing"
          data-dd-action-name="contact me"
          @click="sendSupportRequest"
        >
          {{ $i18n('contactMe') }}
          <font-awesome-icon
            v-if="requestingSupportProcessing"
            icon="spinner"
            class="fa-spin"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .support-button-emphasizer {
    display: none;
    flex-direction: column;
    gap: 2rem;
    position: sticky;
    z-index: 1000;
    left: var(--sdds-spacing-element-32);
    bottom: calc(var(--sdds-spacing-element-32) + 6em);
    background-color: var(--sdds-white);
    color: var(--sdds-blue);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
    width: 20em;
    border-radius: 2rem;
    padding: 2rem 4rem 4rem 4rem;
  }
    .support-button-emphasizer:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      left: 6rem;
      bottom: -4rem;
      border: 2rem solid;
      border-color: var(--sdds-white) transparent transparent;
    }
    .support-button-emphasizer.hidden {
      display: none;
    }
    .support-button-emphasizer__close {
      display: flex;
      place-content: flex-end;
    }
    .support-button-emphasizer__close > span {
      cursor: pointer;
    }
    .support-button-emphasizer__close > span > svg {
      height: var(--sdds-spacing-element-32);
      -webkit-transition: color 0.2s ease-out;
      -moz-transition: color 0.2s ease-out;
      -ms-transition: color 0.2s ease-out;
      -o-transition: color 0.2s ease-out;
      transition: color 0.2s ease-out;
    }
    .support-button-emphasizer__close > span:hover > svg {
      color: var(--sdds-grey-500);
    }
 .support-button {
    position: sticky;
    z-index: 1000;
    left: var(--sdds-spacing-element-32);
    bottom: var(--sdds-spacing-element-32);
    border-radius: 50%;
    border: 0;
    width: 5em;
    height: 5em;
    background-color: var(--sdds-blue);
    color: var(--sdds-white);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 0;
  }
    .support-button > svg {
      max-width: 100%;
    }

  @media only screen and (max-width: 1294px) {
    .support-button {
      left: calc(100vw - 100px);
    }
    .support-button-emphasizer {
      left: calc(100vw - 22.5em);
    }
    .support-button-emphasizer:before {
      left: auto;
      right: 6rem;
    }
  }

  .contact__image {
    display: flex;
    place-content: center;
  }
  .contact__image img {
    width: 12em;
    border-radius: 50%;
    max-width: 100%;
  }

  .support-requested-tick {
    color: green;
    max-width: 10em;
  }

  label {
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: 0em;
    display: block;
    margin-bottom: var(--sdds-spacing-element-8);
    color: var(--sdds-textfield-label-color);
  }
  .sdds-modal-header {
    justify-content: space-between;
  }
  .sdds-modal-header > h3 {
    margin:0;
  }
  .sdds-modal-body>* {
    padding-right: 0;
  }

  /* SDDS v4.5.0 fix */
  .sdds-modal-actions {
    display: flex;
  }
</style>
